import React from 'react';
import cn from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FormattedMessage, useIntl } from 'react-intl';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { useSelector } from 'utils/redux';

import Typography from 'components/atoms/Typography';
import Markdown from 'components/atoms/Markdown';
import LinkInstagramButton from 'components/atoms/Buttons/LinkInstagramButton';

import kolImg from 'assets/images/kol.png';

import loginFacebookPngFr from 'assets/images/login-facebook-fr.png';
import logOrLogBackPngFr from 'assets/images/log-or-logback-fr.png';
import chooseCompanyPngFr from 'assets/images/company-fr.png';
import fbPagesPngFr from 'assets/images/choose-fb-pages-fr.png';
import instagramAccountsPngFr from 'assets/images/choose-instagram-fr.png';
import chekAuthPngFr from 'assets/images/check-authorizations-fr.png';
import loginFacebookPngEn from 'assets/images/login-facebook-en.png';
import logOrLogBackPngEn from 'assets/images/log-or-logback-en.png';
import chooseCompanyPngEn from 'assets/images/company-en.png';
import fbPagesPngEn from 'assets/images/choose-fb-pages-en.png';
import instagramAccountsPngEn from 'assets/images/choose-instagram-en.png';
import chekAuthPngEn from 'assets/images/check-authorizations-en.png';

import safePng from 'assets/images/safe.png';

import { ReactComponent as CaudalieSvg } from 'assets/images/brands/caudalie-paris.svg';
import { ReactComponent as CocaColaSvg } from 'assets/images/brands/coca-cola.svg';
import { ReactComponent as DisneySvg } from 'assets/images/brands/disneyland-paris.svg';
import { ReactComponent as GotbagSvg } from 'assets/images/brands/gotbag.svg';
import { ReactComponent as JaguarSvg } from 'assets/images/brands/jaguar.svg';
import { ReactComponent as LushSvg } from 'assets/images/brands/lush.svg';
import { ReactComponent as NetflixSvg } from 'assets/images/brands/netflix.svg';
import { ReactComponent as UrbanSports } from 'assets/images/brands/urban-sports-club.svg';

import styles from './InstagramLandingPage.module.scss';

const brands = [
  <CocaColaSvg />,
  <JaguarSvg />,
  <NetflixSvg />,
  <UrbanSports />,
  <GotbagSvg />,
  <DisneySvg />,
  <CaudalieSvg />,
  <LushSvg />,
];

const brandList = [...brands, ...brands];

const InstagramLandingPage: React.FC = () => {
  const intl = useIntl();
  const locale = useSelector(({ env }) => env?.locale.slice(0, 2));

  const STEPS = [
    {
      title: 'linkInstagram.step.login.title',
      description: 'linkInstagram.step.login.description',
      picture: locale === 'fr' ? loginFacebookPngFr : loginFacebookPngEn,
      cta: true,
    },
    {
      title: 'linkInstagram.step.logOrLogBack.title',
      description: 'linkInstagram.step.logOrLogBack.description',
      picture: locale === 'fr' ? logOrLogBackPngFr : logOrLogBackPngEn,
    },
    {
      title: 'linkInstagram.step.fbPages.title',
      description: 'linkInstagram.step.fbPages.description',
      picture: locale === 'fr' ? fbPagesPngFr : fbPagesPngEn,
    },
    {
      title: 'linkInstagram.step.company.title',
      description: 'linkInstagram.step.company.description',
      picture: locale === 'fr' ? chooseCompanyPngFr : chooseCompanyPngEn,
    },
    {
      title: 'linkInstagram.step.instagramAccounts.title',
      description: 'linkInstagram.step.instagramAccounts.description',
      picture: locale === 'fr' ? instagramAccountsPngFr : instagramAccountsPngEn,
    },
    {
      title: 'linkInstagram.step.authorizations.title',
      description: 'linkInstagram.step.authorizations.description',
      picture: locale === 'fr' ? chekAuthPngFr : chekAuthPngEn,
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className="flex fdc aifs gap22">
          <Typography
            tag="span"
            className={styles.tag}
          >
            <FormattedMessage id="linkInstagram.header.woomio" />
          </Typography>
          <Typography
            tag="h1"
            variant="section/title large"
            className={cn(styles.title, "text-uppercase")}
          >
            <Markdown>
              {intl.formatMessage({ id: "linkInstagram.header.title" })}
            </Markdown>
          </Typography>
          <Typography variant="text/body medium" className={styles.grey}>
            <FormattedMessage id="linkInstagram.header.description" />
          </Typography>
          <LinkInstagramButton />
        </div>
        <div className={styles.media}>
          <LazyLoadImage
            alt="Influencer"
            width={582}
            src={kolImg}
            effect="blur"
            wrapperProps={{
              style: { transitionDelay: "0.5s" },
            }}
          />
          <div className={cn(styles.brand, styles.brand1)}>
            <DisneySvg />
          </div>
          <div className={cn(styles.brand, styles.brand2)}>
            <CaudalieSvg />
          </div>
          <div className={cn(styles.brand, styles.brand3)}>
            <NetflixSvg />
          </div>
        </div>
      </div>

      <div className={cn(styles.why,"flex fdc jsc tac gap12")}>
        <Typography variant="section/title medium" className={styles.title}>
          <FormattedMessage id="linkInstagram.why.title" />
        </Typography>
        <Typography variant="text/body medium" className={styles.grey}>
          <FormattedMessage id="linkInstagram.why.description" />
        </Typography>
      </div>

      <div className={styles.brands}>
        <div className={styles.track}>
          {brandList.map((Brand, index) => (
            <div key={`brand-${index}`} className={styles.brandItem}>
              {Brand}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.howTo}>
        <div className={cn(styles.top, "flex fdc jsc tac gap12")}>
          <Typography variant="section/title medium">
            <FormattedMessage id="linkInstagram.howTo.title" />
          </Typography>
          <Typography variant="text/body medium" className={styles.description}>
            <Markdown>
              {intl.formatMessage({ id: "linkInstagram.howTo.description" })}
            </Markdown>
          </Typography>
        </div>
        <div className={styles.content}>
          {STEPS.map((step, index) => (
            <div
              key={step.title}
              className={styles.step}
            >
              <div className={styles.picture}>
                <LazyLoadImage
                  alt={`step-${index}`}
                  width={270}
                  src={step.picture}
                  effect="blur"
                  wrapperProps={{
                    style: { transitionDelay: "0.5s" },
                  }}
                />
              </div>
              <div className="flex fdc gap12">
                <div className="flex fdc gap12">
                  <Typography variant="text/body small bold" className={styles.stepNumber}>
                    <FormattedMessage
                      id="linkInstagram.step"
                      values={{ step: index + 1 }}
                    />
                  </Typography>
                  <Typography variant="section/title small">
                    <FormattedMessage id={step.title} />
                  </Typography>
                </div>
                <Typography variant="label/large" className={styles.grey}>
                  <FormattedMessage id={step.description} />
                </Typography>
                {/* {step.cta && (
                  <LinkInstagramButton />
                )} */}
              </div>
            </div>
          ))}
        </div>

        <div className="flex jcc mb20">
          <LinkInstagramButton showDownload />
        </div>
        <div className={styles.safe}>
          <img src={safePng} width={118} alt="safe" />
          <div className="flex fdc gap12">
            <Typography variant="section/title small">
              <FormattedMessage id="linkInstagram.safe.title" />
            </Typography>
            <Typography variant="label/large" className={styles.grey}>
              <FormattedMessage id="linkInstagram.safe.description" />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstagramLandingPage;
