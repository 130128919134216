/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { FetchStatsSharing } from 'types/fetchApi';
import { StatsSharingInStore } from 'reducers/user';

export const fetchStatsSharing = {
    fromApi: ({
        is_working,
        token_expires_at,
        is_needed_by_customer,
        business_id,
    }: FetchStatsSharing): StatsSharingInStore => ({
        isWorking: is_working === true,
        tokenExpiresAt: token_expires_at ? moment(token_expires_at) : null,
        isNeededByCustomer: is_needed_by_customer === true,
        instagramBusinessId: business_id,
        loaded: true,
    }),
};

export const register = {
    toApi: ({token, email, password, locale}) => ({
        email,
            password,
            locale,
        ...token?.length > 0 && { token }
    }),
}

export const login = {
    toApi: ({email, password}) => ({
        user: {
            email: email.trim(),
            password: password.trim(),
        }
    }),
    fromApi: data => ({
        hasCommunityAccess: data?.has_community_access || false
    })
}

export const verifyToken = {
    toApi: ({token, type}) => ({
        token,
        token_type: `${type}_token`,
    })
}

export const confirmRegister = {
    toApi: (token) => ({
        confirmation_token: token
    })
}

export const submitNewPassword = {
    toApi: ({password, confirmPassword, token}) => ({
        password,
        password_confirmation: confirmPassword,
        reset_password_token: token,
    })
}
