import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';
import { getTokenFromCookies } from 'utils/index';
import * as PROFILE from 'constants/profile';
import { Pages, TokenData } from 'types/Instaconnect';
import getLexique from 'locales';
import * as mapper from '../mappers/profile';
import { setClientToken } from './env';
import { fetchStatsSharing } from './user';
import api from '../api';

export const updateField = createAction(PROFILE.FIELD_UPDATE, ({ field, value }) => ({ field, value }));
export const profileLoadInit = createAction(PROFILE.LOAD_INIT);
export const profileLoadComplete = createAction(PROFILE.LOAD_COMPLETE, (profile) => ({ profile }));
export const saveToStore = createAction(PROFILE.SAVE, (profile) => ({
    profile,
}));

export const loadProfile = () => async (dispatch) => {
    dispatch(profileLoadInit());

    const response = await api.fetchProfileSelf();

    if (response) {
        dispatch(profileLoadComplete(mapper.loadProfile.fromApi(response.data)));
    }
};

export const setOnboardingStep = (nextOnboardingStep) => async (_, getState) => {
    await api.putProfileSelf(getState(), mapper.setOnboardingStep.toApi(nextOnboardingStep));
};

export const saveProfile = (form, nextOnboardingStep = null) => async (dispatch, getState) => {
    await api.putProfileSelf(
        getState(),
        mapper.saveProfile.toApi(form, getState().profile.profile.addresses, nextOnboardingStep),
    );

    dispatch(saveToStore(form));
    const {
        env
    } = getState();
    const lexique = getLexique(env.locale).containers.profilePage;
    toast(`${lexique.saved}`);
    return true;
};

export const updateEmail = (query) => async (_, getState) => {
    try {
        return await api.putEmail(getState(), mapper.updateEmail.toApi(query));
    } catch (e) {
        return {
            status: 422,
        };
    }
};

export const updatePassword = (query) => async (dispatch) => {
    try {
        const mappedQuery = mapper.updatePassword.toApi(query);
        const res = await api.putSettingsChangePassword(mappedQuery);

        const token = getTokenFromCookies();
        dispatch(setClientToken(token));

        return res;
    } catch (e) {
        return {
            status: 422,
        };
    }
};

export const saveUserToken = (pages: Pages) => async (dispatch) => {
    const instagramAccount = pages?.instagram?.[0];
    const tokenData = {
        uid: instagramAccount?.uid,
        instagramBusinessId: instagramAccount?.businessId,
        shortLivedToken: instagramAccount?.accessToken,
        triggerMode: 'manual',
    }
    await api.saveUserToken(mapper.saveUserToken.toApi(tokenData));
    await dispatch(fetchStatsSharing());
}

export const saveAutoUserToken = ( token: {accessToken: string, uid: string, instagramBusinessId: string, triggerMode: string} ) => async (dispatch) => {
    const tokenData: TokenData = {
        uid: token?.uid,
        instagramBusinessId: token?.instagramBusinessId,
        shortLivedToken: token?.accessToken,
        triggerMode: token?.triggerMode,
    }
    await api.saveUserToken(mapper.saveUserToken.toApi(tokenData));
    await dispatch(fetchStatsSharing());

    return true;
}

export const saveInstagramSelectedPages = (pages) => () =>
    api.saveInstagramPages(mapper.sendSelectedInstagramPages.toApi(pages));

export const saveFacebookSelectedPages = (pages) => async () =>
    api.saveFacebookPages(mapper.sendSelectedFacebookPages.toApi(pages));
