/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import _orderBy from 'lodash.orderby';

import * as MESSAGING from 'constants/messaging'

export enum MESSAGE_TYPES {
  message = 'message',
  notification = 'notification'
}

export enum MESSAGE_SUBTYPES {
  'stateUpdated' = 'proposition_state_update',
  'statsSharingApproval' = 'stats_sharing_approval',
  'statsSharingHalted' = 'stats_sharing_halted',
  'statsSharingResumed' = 'stats_sharing_resumed',
  'statsSharingRequest' = 'stats_sharing_request',
  'answerBrief' = 'answer_brief',
}

export enum PROFILE_TYPES {
  contact = 'contact',
  profile = 'profile'
}

export enum ENUM_TABS {
  all = 'all',
  unread = 'unread',
  draft = 'draft'
}

export interface AttachmentState {
  id?:string
  size?: number
  url: string;
  fileName: string;
  name?: string;
  _destroy?: boolean;
}

export interface ProfileState {
  id: number;
  name: string;
  fullName: string;
  pseudo: string;
  type?: PROFILE_TYPES;
  pictureUrl?: string;
  isActive: boolean;
}

export interface MessageState {
  id: string;
  createdAt: string;
  subject: string;
  content: string;
  isThreadStart?: boolean;
  messageType: MESSAGE_TYPES;
  subtype?: MESSAGE_SUBTYPES | null;
  writer: ProfileState;
  attachments: AttachmentState[];
}

export interface ConversationState {
  id: string | number;
  lastMessageAt: string;
  lastMessage: MessageState;
  draft?: DraftState;
  hasBeenRead: boolean;
  participants: ProfileState[];
  subject: string;
  unreadMessages: number;
}

export interface DraftState {
  id: string | number;
  conversationId: string | number;
  createdAt: string;
  updatedAt?: string;
  participants?: ProfileState[];
  content: string;
  attachments?: AttachmentState[];
}

export interface SubjectThreadState {
  id: number;
  createdAt: string;
  subject: string;
}

export interface AllMessages {
  rows: MessageState[];
  total: number;
  page: number;
  perPage: number;
  hasMore: boolean;
}
export interface CurrentConversationState {
  id: string | number;
  to: ProfileState;
  subject: string;
  subjectThreads: SubjectThreadState[];
  participants: ProfileState[];
  lastMessageAt: string;
  messages: AllMessages;
  draft?: DraftState;
}

export interface ConversationFilters {
  name: string;
  tab: ENUM_TABS;
  page: number,
  perPage: number,
}

export interface MessagingState {
  displayPanel: boolean;
  allUnreadMessages: number;
  conversations: ConversationState[];
  total: number;
  hasMore: boolean;
  draftMessages: DraftState[];
  totalDraft: number;
  hasDraftMore: boolean;
  currentConversation?: CurrentConversationState;
  filters: ConversationFilters;
  hasConversations: boolean;
}

export const DEFAULT_FILTERS = {
  name: '',
  tab: ENUM_TABS.all,
  page: 0,
  perPage: 20,
};

export const DEFAULT_PER_PAGE = 10;

const INITIAL_CURRENT_CONVERSATION = {
  id: null,
  to: null,
  subject: '',
  subjectThreads: [],
  participants: [],
  lastMessageAt: '',
  messages: {
    rows: [],
    total: 0,
    page: 0,
    perPage: DEFAULT_PER_PAGE,
    hasMore: true,
  },
  draft: null
};

const initialState: MessagingState = {
  displayPanel: true,
  allUnreadMessages: 0,
  conversations: [],
  total: 0,
  hasMore: true,
  draftMessages: [],
  totalDraft: 0,
  hasDraftMore: false,
  currentConversation: INITIAL_CURRENT_CONVERSATION,
  filters: DEFAULT_FILTERS,
  hasConversations: false,
}

const reducers = {
  [MESSAGING.LOAD_CONVERSATIONS]: (state, { payload: {conversations, total} }) => {
    const { filters} = state;

    state.conversations = filters.page === 0
      ? conversations
      : [...state.conversations, ...conversations]
    state.total = total;
    state.hasConversations = !(conversations?.length === 0 && filters?.name?.length === 0 && filters?.tab === ENUM_TABS.all);
    state.hasMore = (filters.page + 1) < Math.ceil(total / filters.perPage);
  },
  [MESSAGING.UPDATE_CONVERSATIONS_WS]: (state, { payload: messages }) => {
    if (messages?.length > 0) state.hasConversations = true;

    messages?.forEach(item => {
      const getIndex = state.conversations.findIndex( ({ id }) => id === item.id);
      if (getIndex !== -1) state.conversations[getIndex] = item;
      if (getIndex === -1) state.conversations = [item, ...state.conversations];
    });
  },
  [MESSAGING.UPDATE_DRAFT]: (state, { payload: draftUpdated }) => {
    // Update Inbox preview
    const getCardMessageIndex = state.conversations.findIndex( conversation => conversation.id === draftUpdated.conversationId);
    if (getCardMessageIndex > -1) {
      state.conversations[getCardMessageIndex].draft = draftUpdated;
    }
    const getCardDraftIndex = state.draftMessages.findIndex( draft => draft.conversationId === draftUpdated.conversationId);
    if (getCardDraftIndex > -1) {
      state.draftMessages[getCardDraftIndex] = draftUpdated;
    } else {
      // new draft
      state.draftMessages = [draftUpdated];
    }

    // Update input
    state.currentConversation.draft = draftUpdated;
  },
  [MESSAGING.SORT_INBOX_LIST]: (state) => {
    state.conversations = _orderBy(state.conversations, ['lastMessageAt'], 'desc');
  },
  [MESSAGING.LOAD_DRAFT_MESSAGES]: (state, { payload: {total, conversations} }) => {
    const { filters} = state;

    state.draftMessages = filters.page === 0
      ? conversations
      : [...state.draftMessages, ...conversations]
    state.totalDraft = total;
    state.hasDraftMore = (filters.page + 1) < Math.ceil(total / filters.perPage);
  },
  [MESSAGING.DELETE_DRAFT]: (state, { payload: draftId }) => {
    // Remove Inbox preview
    state.draftMessages = state.draftMessages.filter(({ id }) => id !== draftId);
    const getCardMessageIndex = state.conversations.findIndex( conversation => conversation.draft?.id === draftId)
    if (getCardMessageIndex > -1) state.conversations[getCardMessageIndex].draft = null;

    // Remove from input
    if (state.currentConversation?.draft?.id === draftId) state.currentConversation.draft = null;
  },
  [MESSAGING.SET_ALL_UNREAD_MESSAGES]: (state, { payload: count }) => { state.allUnreadMessages = count},
  [MESSAGING.LOAD_CONVERSATION_INFOS]: (state, { payload: { unreadMessages, ...data} }) => {
    const index = state.conversations.findIndex(c => c.id === data.id);
    state.conversations[index] = {
      ...state.conversations[index],
      unreadMessages,
    }
    state.currentConversation = {...state.currentConversation, ...data};
  },
  [MESSAGING.LOAD_ALL_MESSAGES]: (state, { payload: {rows, total, page, perPage} }) => {
    const newMessages = {
      total,
      page,
      perPage: perPage || DEFAULT_PER_PAGE,
      hasMore: (page + 1) < Math.ceil(total / (perPage || DEFAULT_PER_PAGE)),
      rows: page === 0
        ? rows
        : [...rows, ...state.currentConversation.messages.rows]
    }
    state.currentConversation.messages = newMessages;
  },
  [MESSAGING.RESET_CURRENT_CONVERSATION]: (state) => {state.currentConversation = INITIAL_CURRENT_CONVERSATION},
  [MESSAGING.ADD_NEW_MESSAGE]: (state, { payload: data }) => {
    state.currentConversation.messages.rows = [...state.currentConversation.messages.rows, data]
  },
  [MESSAGING.HANDLE_VIEW]: (state, { payload: { displayPanel }}) => { state.displayPanel = displayPanel },
  [MESSAGING.UPDATE_FILTERS]: (state, { payload }) => {
    state.filters = {
      ...state.filters,
      ...payload
    }
  },
}

export default createReducer(initialState, reducers);
