
import React,  { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Loader } from '@brandandcelebrities/kolkit';
import {
  Button,
  IconButton,
  Checkbox,
  FormControl,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  OutlinedInput,
  RadioGroup,
  Radio,
} from '@material-ui/core';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CloseIcon from '@material-ui/icons/Close';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Typography from 'components/atoms/Typography';
import Markdown from 'components/atoms/Markdown';
import Avatar from 'components/molecules/Avatar';

import { conf } from 'config/env';
import api from 'api';
import { useSelector } from 'utils/redux';

import styles from './LinkInstagramButton.module.scss';


const LOGIN_SCOPE = `
  catalog_management,
  pages_show_list,
  business_management,
  instagram_basic,
  instagram_manage_insights,
  pages_read_engagement,
  email
`;

const USER_AND_FB_PAGES_SCOPES = `/me?fields=
  id,
  email,
  accounts {
    access_token,
    name,
    username,
    fan_count,
    category,
    picture {
      url
    },
    instagram_business_account {
      id,
      name,
      username,
      followers_count,
      profile_picture_url,
      ig_id
    }
  }
`;

type STEP = 'warning' |
'selectInstagram' |
'accountExists' |
'newAccount' |
'missingEmail' |
'failed';

interface Props {
  showDownload?: boolean
}

const LinkInstagramButton: React.FC<Props> = ({ showDownload }) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState<STEP>('warning');

  const [instaCoData, setInstaCoData] = useState({
    email: null,
    instagramBusinessAccounts: [],
    selected: null,
  });

  const locale = useSelector(({ env }) => env.locale);

  const handleCheck = useCallback((e) => setChecked(e.target.checked), []);

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(
    () => {
      setOpen(false);
      setLoading(false);
      setChecked(false);
      setStep('warning');
      setInstaCoData({
        email: null,
        instagramBusinessAccounts: [],
        selected: null,
      });
    }, []);

  const handleEmailChange = useCallback(
    (value) => {
      setInstaCoData(d => ({
        ...d,
        email: value,
      }))
    },
    []
  );

  const handlePagesFail = useCallback(
    () => {
      setLoading(false);
      setStep('failed');
    },
    [],
  );

  const handleSubmit = useCallback(
    async ({ email, instagram, facebook }) => {
      try {
        const response = await api.registerByInstaCo({ email, instagram, facebook });

        if (response?.data?.user_was_existing) {
          setStep('accountExists');
        } else {
          setStep('newAccount');
        }
      } catch(error) {
        handlePagesFail();
        return false;
      }
    },
    [handlePagesFail]
  );

  const handleSubmitWithCustomEmail = useCallback(
    () => {
      if (instaCoData?.instagramBusinessAccounts?.length === 1) {
        handleSubmit({
          email: instaCoData.email,
          instagram: instaCoData?.instagramBusinessAccounts.find(instagram => instagram?.uid === instaCoData.selected),
          facebook: { access_token: window.FB.getAccessToken() }
        });
      } else {
        setStep('selectInstagram');
      }
    },
    [instaCoData, handleSubmit]
  );

  const getAllPagesCallback = useCallback(
    (response) => {
      if (response?.status === 'connected' && response?.authResponse?.accessToken) {
        window.FB?.api?.(
          USER_AND_FB_PAGES_SCOPES,
          async (fbData) => {
            const instagramBusinessAccounts = fbData?.accounts?.data
              ?.filter(fbPage => fbPage?.instagram_business_account?.id) // Only FB page has instagram business account
              ?.map(fbPage => ({
                short_lived_token: fbPage?.access_token,
                business_id: fbPage?.instagram_business_account?.id,
                uid: String(fbPage?.instagram_business_account?.ig_id),
                name: fbPage?.instagram_business_account?.name,
                username: fbPage?.instagram_business_account?.username,
                profile_picture_url: fbPage?.instagram_business_account?.profile_picture_url || fbPage?.picture?.data?.url,
              }));

            if (instagramBusinessAccounts?.length > 0) {
              setInstaCoData({
                email: fbData?.email,
                instagramBusinessAccounts,
                selected: instagramBusinessAccounts[0]?.uid,
              });

              if (!fbData?.email || fbData?.email === '') {
                setStep('missingEmail');
              }

              if (fbData?.email) {
                if (instagramBusinessAccounts?.length === 1) {
                  await handleSubmit({
                    email: fbData?.email,
                    instagram: instagramBusinessAccounts[0],
                    facebook: { access_token: window.FB.getAccessToken() }
                  });
                } else {
                  setStep('selectInstagram');
                }
              }
            } else {
              handlePagesFail();
            }
          }
        );
      } else {
        handlePagesFail();
      }

      setLoading(false);
    },
    [handlePagesFail, handleSubmit]
  );

  const getAllPages = useCallback(
     () => {
      setLoading(true);
      window.FB?.login(getAllPagesCallback, {
        scope: LOGIN_SCOPE
      });
    },
    [getAllPagesCallback]
  );

  const handleRadioChange = useCallback(
    (event) => {
      setInstaCoData(d => ({
        ...d,
        selected: event?.target?.value
      }))
    },
    []
  );

  useEffect(
    () => {
      if (window.FB) {
        window.FB.init({
          appId: conf.fbc.clientId,
          cookie: true,
          xfbml: true,
          version: 'v21.0',
        });
      }
    },
    []
  );

  const renderContent = useMemo(
    () => {
      if (step === 'warning') return (<>
        <DialogContent className={styles.dialogContent}>
          <div className="flex fdc gap22">
            <Typography variant="title/modal-title">
              <FormattedMessage id="linkInstagram.modal.warning.title" />
            </Typography>
            <Typography variant="text/body small" className={styles.body}>
              <FormattedMessage id="linkInstagram.modal.warning.subTitle" />
            </Typography>
            <Typography variant="text/body small" className={styles.body}>
              <Markdown>
                {intl.formatMessage({ id: "linkInstagram.modal.warning.message" })}
              </Markdown>
            </Typography>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: styles.dialogActions }}>
          <Button
            classes={{ root: styles.button, endIcon: styles.endIcon }}
            endIcon={<ArrowRightAltIcon />}
            onClick={getAllPages}
          >
            <FormattedMessage id="linkInstagram.button" />
          </Button>
        </DialogActions>
      </>);

      if (step === 'newAccount') return (<>
        <DialogContent className={styles.dialogContent}>
          <div className="flex fdc gap22 aic tac">
            <div className={styles.headIcon}>
              <EmailTwoToneIcon/>
            </div>
            <Typography variant="title/modal-title">
              <FormattedMessage id="linkInstagram.modal.success.newAccount.title" />
            </Typography>
            <Typography variant="text/body small" className={styles.body}>
              <Markdown>
                {intl.formatMessage({ id: "linkInstagram.modal.success.newAccount.message" })}
              </Markdown>
            </Typography>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: styles.dialogActions }}>
          <Button
            classes={{ root: styles.button, endIcon: styles.endIcon }}
            onClick={handleClose}
          >
            <FormattedMessage id="global.cta.close" />
          </Button>
        </DialogActions>
      </>);

      if (step === 'accountExists') return (<>
        <DialogContent className={styles.dialogContent}>
          <div className="flex fdc gap22 aic tac">
            <div className={styles.headIcon}>
              <VerifiedUserTwoToneIcon/>
            </div>
            <Typography variant="title/modal-title">
              <FormattedMessage id="linkInstagram.modal.success.accountExists.title" />
            </Typography>
            <Typography variant="text/body small" className={styles.body}>
              <Markdown>
                {intl.formatMessage({ id: "linkInstagram.modal.success.accountExists.message" })}
              </Markdown>
            </Typography>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: styles.dialogActions }}>
          <Button
            classes={{ root: styles.button, endIcon: styles.endIcon }}
            onClick={handleClose}
          >
            <FormattedMessage id="global.cta.close" />
          </Button>
        </DialogActions>
      </>);

      if (step === 'missingEmail') return (<>
        <DialogContent className={styles.dialogContent}>
          <div className="flex fdc gap22">
            <Typography variant="title/modal-title">
              <FormattedMessage id="linkInstagram.modal.success.missingEmail.title" />
            </Typography>
            <Typography variant="text/body small" className={styles.body}>
              <Markdown>
                {intl.formatMessage({ id: "linkInstagram.modal.success.missingEmail.message" })}
              </Markdown>
            </Typography>
            <OutlinedInput
              placeholder={intl.formatMessage({ id: 'linkInstagram.modal.success.missingEmail.placeholder'})}
              value={instaCoData.email}
              onChange={(event) => handleEmailChange(event?.target?.value)}
              classes={{
                root: styles.textField,
                focused: styles.focused,
                notchedOutline: styles.notchedOutline
              }}
            />
          </div>
        </DialogContent>
        <DialogActions classes={{ root: styles.dialogActions }}>
          <Button
            classes={{ root: styles.button }}
            onClick={handleSubmitWithCustomEmail}
            disabled={instaCoData?.email?.trim() === ''}
          >
            <FormattedMessage id="global.cta.valide" />
          </Button>
        </DialogActions>
      </>);

      if (step === 'selectInstagram') return (<>
        <DialogContent className={styles.dialogContent}>
          <div className="flex fdc gap22 aic tac">
            <div className={styles.headIcon}>
              <VerifiedUserTwoToneIcon/>
            </div>
            <Typography variant="title/modal-title">
              <FormattedMessage id="linkInstagram.modal.multipleInstagram.title" />
            </Typography>
            <Typography variant="text/body small" className={styles.body}>
              <Markdown>
                {intl.formatMessage({ id: "linkInstagram.modal.multipleInstagram.message" })}
              </Markdown>
            </Typography>
            <FormControl>
              <RadioGroup
                value={instaCoData.selected}
                onChange={handleRadioChange}
              >
                {instaCoData.instagramBusinessAccounts.map(instagram => (
                  <FormControlLabel
                    key={instagram?.uid}
                    value={instagram?.uid}
                    control={<Radio classes={{ root: styles.radio, checked: styles.checked }} />}
                    label={
                      <Avatar
                        name={`${instagram?.name} - ${instagram?.username}`}
                        avatar={instagram?.profile_picture_url}
                      />
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: styles.dialogActions }}>
          <Button
            classes={{ root: styles.button }}
            onClick={handleSubmit}
          >
            <FormattedMessage id="global.cta.valide" />
          </Button>
        </DialogActions>
      </>);

      if (step === 'failed') return (<>
        <DialogContent className={styles.dialogContent}>
          <div className="flex fdc gap22 aic tac">
            <div className={cn(styles.headIcon, styles.error)}>
              <HighlightOffIcon/>
            </div>
            <Typography variant="title/modal-title">
              <FormattedMessage id="linkInstagram.modal.failed.title" />
            </Typography>
            <Typography variant="text/body small" className={styles.body}>
              <Markdown>
                {intl.formatMessage({ id: "linkInstagram.modal.failed.message" })}
              </Markdown>
            </Typography>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: styles.dialogActions }}>
          <Button
            classes={{ root: styles.button }}
            onClick={getAllPages}
          >
            <FormattedMessage id="global.cta.tryAgain" />
          </Button>
        </DialogActions>
      </>);
    },
    [
      instaCoData,
      getAllPages,
      handleEmailChange,
      handleSubmitWithCustomEmail,
      handleRadioChange,
      handleSubmit,
      handleClose,
      intl,
      step,
    ]
  );

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <script
          id="facebook-jssdk"
          src="https://connect.facebook.net/fr_FR/sdk.js"
          crossOrigin="anonymous"
          async
          defer
        />
      </Helmet>
      <div className={styles.triggerButtons}>
        <Button
          classes={{
            root: styles.button,
            endIcon: styles.endIcon,
            disabled: styles.disabled,
          }}
          endIcon={<ArrowRightAltIcon />}
          onClick={handleOpen}
          disabled={!checked}
        >
          <FormattedMessage id="linkInstagram.button" />
        </Button>
        {showDownload && (
          <Button
            endIcon={<ArrowRightAltIcon />}
            classes={{
              root: styles.buttonPdf,
              endIcon: styles.endIcon,
            }}
            onClick={e => e.stopPropagation()}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              download={`15-07-24_Insta_Connect_Onboarding_${locale.slice(0, 2) === 'fr' ? 'FR' : 'EN'}.pdf`}
              href={`https://influence.kolsquare.com/pdf/15-07-24_Insta_Connect_Onboarding_${locale.slice(0, 2) === 'fr' ? 'FR' : 'EN'}.pdf`}
            >
              <FormattedMessage id="linkInstagram.downloadPdf" />
            </a>
          </Button>
        )}
      </div>
      <div className={styles.termsConditions}>
        <Checkbox
          color="default"
          checked={checked}
          onChange={handleCheck}
        />
        <Typography variant="label/large">
          <Markdown>
            {intl.formatMessage({ id: "linkInstagram.termsConditions" })}
          </Markdown>
        </Typography>
      </div>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xs"
        classes={{ root: styles.dialog, paper: styles.paper }}
      >
        <DialogTitle
          disableTypography
          classes={{ root: styles.dialogTitle }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {loading && <Loader full background="rgba(255, 255, 255, .75)" /> }

        {renderContent}
      </Dialog>
    </div>
  );
};

export default LinkInstagramButton;
