import axios from 'axios';
import moment from 'moment';

import { randomBool, randomFromArray, randomRange, randomizePost } from 'utils/randomize';
import resolveTimeout from 'utils/resolveTimeout';
import {getTimeRangeParameters} from 'utils/timeRange';

import {PER_PAGE} from 'constants/ui';
import {TimeRange, GlobalFilters, SocialNetworksFilter, SNATopPostsParams} from 'types/Filters';
import {SNAAPIData, Networks, SnaDataChartFetch} from 'types/snas.d';
import {
  FetchInitApp,
  FetchPostsStatistics,
  FetchProfile,
  FetchInfoSna,
  FetchCommunity,
  FetchHeader,
  SearchCardFiltersParams,
  FetchStats,
  FetchPostsStats,
  FetchTopHashtag,
  FetchTopFlopPosts,
  FetchOk,
  FetchProposition,
  FacebookPagesParams,
  FetchStatsSharing,
  FetchRequestStatsSharingInfos,
  FetchLogin,
} from 'types/fetchApi';
import * as mapper from '../mappers/profile';
import {getHeaders, logCall} from './index';
import {conf} from '../config/env';
import {ApiPromise, APIResponseDataPromise, Api, APIIDResponsePromise, APIResponseData} from './api.d';

import {
  fetchConversations,
  getConversationInfos,
  getAllMessages,
  postMessage,
  getDraftMessages,
  postDraftMessage,
  putDraftMessage,
  deleteDraftMessage
} from './messaging.api.dev';

const randomDateBetween = (startAt, endAt) => {
  const start = new Date(startAt);
  const end = new Date(endAt);
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

const generateDates = (startAt, endAt) => {
  const nbItems = Math.ceil(Math.random() * 200);
  const dates = new Array(nbItems).fill('X').map(() => randomDateBetween(startAt, endAt));
  const sortedDates = dates.sort((a: Date, b: Date) => {
    return a.getTime() - b.getTime();
  });
  return sortedDates.map((d) => moment(d).format());
};

const isPlaceholder = process.env.REACT_APP_PLACEHOLDER === 'true';

export const fetchHeader = () => {
  return new Promise<APIResponseData<FetchHeader>>((resolve) => {
    const data = {
      engagement_4m1m: 2000,
      accounts: [
        {
          label: 'youtube',
          community_count: 4012,
          engagement_3m: 555,
        },
        {
          label: 'facebook',
          community_count: 3000,
          engagement_3m: 1111,
        },
        {
          label: 'instagram',
          community_count: 2502,
          engagement_3m: null,
        },
        {
          label: 'tiktok',
          community_count: 3502,
          engagement_3m: null,
        },
      ],
    };
    resolveTimeout(
      resolve({data}),
      0
    );
  });
};

const getAccountIdsParameters = (socialNetworks: SocialNetworksFilter) => {
  const networks = Object.keys(socialNetworks).map((key) => socialNetworks[key]);
  const snaFiltered = networks.filter(({selected}) => selected === true);

  return snaFiltered.length === 0 ? networks.map((item) => item.id) : snaFiltered.map((item) => item.id);
};

export const fetchPosts = (
  token,
  snas,
  globalFilters: GlobalFilters,
  searchCardFilters: SearchCardFiltersParams,
  page = 0,
) => {
  const {start_at, end_at} = getTimeRangeParameters(globalFilters.timeRangeFilter);
  const account_ids = getAccountIdsParameters(globalFilters.socialNetworksFilter);

  // eslint-disable-next-line
  logCall(
    'get',
    'posts',
    {
      content: searchCardFilters.search,
      account_ids,
      sort_by: searchCardFilters.sortFilter.selectedOption,
      start_at,
      end_at,
      page,
      per_page: PER_PAGE,
    },
  );

  const getStats = () => {
    const stats: FetchStats[] = snas.map((sna) => {
      const posts: FetchPostsStatistics = {
        total: 0,
        matched_posts: randomRange(25, 225),
        matched_stories: randomRange(50, 300),
        matched_percent: 6.963788300835655,
      };
      const pctMatch = randomRange(0, 100);
      const pctEngagement = randomRange(0, 100);
      posts.total = posts.matched_posts + posts.matched_stories;
      return {
        social_network: sna.label,
        sna_id: sna.id,
        posts,
        avg_matched_engagement: {
          absolute: (pctMatch / 100) * posts.total,
          percent: pctMatch,
        },
        avg_engagement_per_post: {
          absolute: (pctEngagement / 100) * posts.total,
          percent: pctEngagement,
        },
      };
    });
    return stats;
  };
  return new Promise<APIResponseData<FetchPostsStats>>((resolve) => {
    const data = {
      total: 12 * 6,
      stats: getStats(),
      posts: [
        {
          id: 'instagram1350868947343331969_399033348',
          social_network: Networks.instagram,
          published_at: '2016-09-30T17:22:29.000+0000',
          content:
            'La recette du saucisson au <em>CHOCOLAT</em> est en ligne sur ma chaine 😍😋 #saucisson #<em>chocolat</em> #<em>chocolate</em> #recette #yotube #latelierderoxane #miam #instapic #instafood',
          picture_url:
            'https://upload.wikimedia.org/wikipedia/commons/d/d0/Golden_Eagle_RWD2.jpg',
          content_url: 'https://www.instagram.com/p/BK_QCaAgVKB/',
          username: '399033348',
          story: null,
          is_igtv: true,
          type: 'video',
          avg_engagement: {
            absolute: 4308,
            percent: 0.8378177065809919,
          },
          engagement_details: {
            likes: 4259,
            comments: 49,
            reach: 168,
            impressions: 138,
            shares: 13,
            videos_views: 138,
            saved: 156,
            plays: 184,
          },
        },
        {
          id: 'instagram1375501594430391538_399033348',
          social_network: Networks.instagram,
          published_at: '2016-11-03T17:03:09.000+0000',
          content:
            'Et des macarons <em>chocolat</em> ? Ça vous tente ? 😋 #latelierderoxane #instapic #instafood #miam #<em>chocolat</em> #<em>chocolate</em> #macaroons #macarons #pastry #patisserie',
          picture_url: 'https://upload.wikimedia.org/wikipedia/commons/9/91/GoldenEagle-Nova.jpg',
          content_url: 'https://www.instagram.com/p/BMWw2mUAcTy/',
          username: '399033348',
          story: true,
          avg_engagement: {
            absolute: 6216,
            percent: 1.20888460169625,
          },
          engagement_details: {
            likes: 6137,
            comments: 79,
            reach: 168,
            impressions: 138,
          },
        },
        {
          id: 'facebook1045168952182763_1162767753756215',
          social_network: Networks.facebook,
          published_at: '2016-04-15T16:14:12.000+0000',
          content:
            '🍫 MON LAYER CAKE NOISETTE & <em>CHOCOLAT</em> 🍫\nLa recette, ça vous tente ? \nBisous <em>chocolatés</em> ❤️💋',
          picture_url:
            'https://nails.newsela.com/s3/newsela-media/article_media/2015/08/walrus-alaska-61ca23de.jpg?crop=0,630,5994,4002&height=497&width=885',
          content_url: 'https://www.facebook.com/1045168952182763/posts/1162767753756215',
          username: '1045168952182763',
          avg_engagement: {
            absolute: 799,
            percent: 0.3873375993794842,
          },
          engagement_details: {
            likes: 713,
            comments: 62,
            shares: 24,
            saved: 156,
          },
        },
        {
          id: 'instagram1042126495481616158_399033348',
          social_network: randomFromArray([
            Networks.instagram,
            Networks.youtube,
            Networks.instagram,
            Networks.tiktok,
          ]),
          published_at: '2015-08-01T17:46:20.000+0000',
          content:
            'Pure #gourmandise ! #chamallow & #<em>chocolat</em> 😋😋. #<em>chocolate</em> #miam #instafood #instapics #foodporn #foodpics',
          picture_url: 'http://www.animalfactsencyclopedia.com/images/walrus-portrait.jpg',
          content_url: 'https://www.instagram.com/p/52YIa2NFce/',
          username: '399033348',
          avg_engagement: {
            absolute: 951,
            percent: 0.184950009043297,
          },
          engagement_details: {
            likes: 946,
            comments: 5,
            reach: 168,
            impressions: 138,
            shares: 13,
          },
        },
        {
          id: 'instagram1229073065870382828_399033348',
          social_network: randomFromArray([
            Networks.instagram,
            Networks.youtube,
            Networks.instagram,
            Networks.tiktok,
          ]),
          published_at: '2016-04-15T16:15:48.000+0000',
          content:
            '🍫 MON LAYER CAKE NOISETTE & <em>CHOCOLAT</em> 🍫\nLa recette, ça vous tente ? \nBisous <em>chocolatés</em> ❤️💋 #layer #cake #layercake #noisette #<em>chocolat</em> #<em>chocolate</em> #miam #sodelicious #instagood #lemeilleurpatissier #latelierderoxane #instapic #foodporn #followme #pastry #patisserie',
          picture_url: 'https://dst15js82dk7j.cloudfront.net/64605/76734250-6drco.jpg',
          content_url: 'https://www.instagram.com/p/BEOi3EGNFbs/',
          username: '399033348',
          avg_engagement: {
            absolute: 1403,
            percent: 0.2728547452026768,
          },
          engagement_details: {
            likes: 1366,
            comments: 37,
          },
        },
        {
          id: 'instagram1281991617699384910_399033348',
          social_network: randomFromArray([
            Networks.instagram,
            Networks.youtube,
            Networks.instagram,
            Networks.tiktok,
          ]),
          published_at: '2016-06-27T16:35:31.000+0000',
          content:
            "🎂 1 an de rencontre ça se fête non ?? Retrouvez la recette de notre gâteau d'anniversaire <em>chocolaté</em> sur ma chaine YouTube : L'atelier de roxane 🍰\nAvec mon chouchou @carlarsenault 😍Bisous <em>chocolatés</em> ❤️💋 #gateau #<em>chocolate</em> #<em>chocolat</em> #anniversaire #birthday #cake #gourmandise #rencontre #lemeilleurpatissier",
          picture_url:
            'https://dst15js82dk7j.cloudfront.net/64605/76732920-sHU6s.jpg?name=Golden_eagles_H.jpg',
          content_url: 'https://www.instagram.com/p/BHKjJWCAA5O/',
          username: '399033348',
          avg_engagement: {
            absolute: 2458,
            percent: 0.4780306227428223,
          },
          engagement_details: {
            likes: 2443,
            comments: 15,
          },
        },
        {
          id: 'instagram1486200437715994729_399033348',
          social_network: randomFromArray([
            Networks.instagram,
            Networks.youtube,
            Networks.instagram,
            Networks.tiktok,
          ]),
          published_at: '2019-04-05T10:42:00.000+0000',
          content:
            '🎥Nouvelle video en ligne ! Pour pâques, Louane vous apprend à faire des œufs ..... au <em>chocolat</em> ! 😋 \n_________________________________ \n#paques #easter #recette #youtube #fun #latelierderoxane #jevousaime #instafood #<em>chocolate</em> #<em>chocolat</em>',
          picture_url: 'https://dst15js82dk7j.cloudfront.net/64605/76734264-9AiHS.jpg',
          content_url: 'https://www.instagram.com/p/BSgC2yDF4hp/',
          username: '399033348',
          avg_engagement: {
            absolute: 18382,
            percent: 3.574922256817965,
          },
          engagement_details: {
            likes: 18245,
            comments: 137,
          },
        },
        {
          id: 'instagram1494133984896299270_399033348',
          social_network: randomFromArray([
            Networks.instagram,
            Networks.youtube,
            Networks.instagram,
            Networks.tiktok,
          ]),
          published_at: '2019-04-16T09:24:32.000+0000',
          content:
            '🍫Bon bin ...... je suis pas prête de guérir de mon addiction au <em>chocolat</em> 😅😁 JOYEUSES PÂQUES MES GOURMANDISES ❤🐣\n______________________________________\n#paques #easter #<em>chocolat</em> #<em>chocolate</em> #gourmandise #addiction #latelierderoxane #jevousaime #loveyou #instapic',
          picture_url: 'https://dst15js82dk7j.cloudfront.net/64605/76734268-fjMh0.jpg',
          content_url: 'https://www.instagram.com/p/BS8OvFolTEG/',
          username: '399033348',
          avg_engagement: {
            absolute: 25244,
            percent: 4.909440618600409,
          },
          engagement_details: {
            likes: 24978,
            comments: 266,
            reach: 168,
            impressions: 138,
          },
        },
        {
          id: 'instagram1434176966059299790_399033348',
          social_network: Networks.instagram,
          published_at: '2019-01-23T16:00:38.000+0000',
          content:
            '🎥NOUVELLE VIDÉO EN LIGNE😋 (lien en bio)🎥 Sauras tu résister à mes profiteroles chantilly & sauce <em>chocolat</em> ? ❤😋🍫 #<em>chocolate</em> #<em>chocolat</em> #gourmandise #miam #foodporn #recette #youtube #latelierderoxane #instafood',
          picture_url:
            'https://upload.wikimedia.org/wikipedia/commons/d/d0/Golden_Eagle_RWD2.jpg',
          content_url: 'https://www.instagram.com/p/BPnOFofAxPO/',
          username: '399033348',
          is_igtv: true,
          avg_engagement: {
            absolute: 14450,
            percent: 2.810228844033271,
          },
          engagement_details: {
            likes: 14354,
            comments: 96,
            reach: 168,
            impressions: 138,
            videos_views: 139,
          },
        },
        {
          id: 'instagram1321902433726707591_399033348',
          social_network: Networks.instagram,
          published_at: '2016-08-21T18:11:11.000+0000',
          content:
            'Retrouvez la recette des donuts au four sur ma chaine 😋 bisous <em>chocolatés</em> ! #donuts #Homer #simpsons #<em>chocolat</em> #<em>chocolate</em> #miam #foodporn #youtube #latelierderoxane #instafood',
          picture_url: 'https://upload.wikimedia.org/wikipedia/commons/9/91/GoldenEagle-Nova.jpg',
          content_url: 'https://www.instagram.com/p/BJYV0J-ATeH/',
          username: '399033348',
          is_reel: true,
          avg_engagement: {
            absolute: 4266,
            percent: 0.8296495673803417,
          },
          engagement_details: {
            likes: 4199,
            comments: 67,
            reach: 168,
            impressions: 138,
            videos_views: 139,
            plays: 13,
          },
        },
        {
          id: 'instagram1349422433412724232_399033348',
          social_network: Networks.instagram,
          published_at: '2016-09-28T17:28:31.000+0000',
          content:
            'La recette du mugcake vanille - <em>chocolat</em> est en ligne sur ma chaine 😋❄️❄️ #reinedesneiges #frozen #olaf #mugcake #vanille #<em>chocolat</em> #<em>chocolate</em> #sodelicious #miam #youtube #latelierderoxane #instapic #instafood',
          picture_url:
            'https://nails.newsela.com/s3/newsela-media/article_media/2015/08/walrus-alaska-61ca23de.jpg?crop=0,630,5994,4002&height=497&width=885',
          content_url: 'https://www.instagram.com/p/BK6HI27gjYI/',
          username: '399033348',
          avg_engagement: {
            absolute: 4804,
            percent: 0.9342795409505769,
          },
          engagement_details: {
            likes: 4759,
            comments: 45,
          },
        },
        {
          id: 'instagram1357935309060332837_399033348',
          social_network: Networks.instagram,
          published_at: '2016-10-10T11:22:05.000+0000',
          content:
            'Ma recette de la tarte au <em>chocolat</em> banane est en ligne !! Avec un bonus souvenir #LMP 😍🍫 #tarte #<em>chocolat</em> #<em>chocolate</em> #recipe #youtube #latelierderoxane #miam #banana #instapic #instafood #foodporn #pastry',
          picture_url: 'https://dst15js82dk7j.cloudfront.net/64605/76734264-9AiHS.jpg',
          content_url: 'https://www.instagram.com/p/BLYWvgMgF0l/',
          username: '399033348',
          avg_engagement: {
            absolute: 4283,
            percent: 0.8329557189615573,
          },
          engagement_details: {
            likes: 4247,
            comments: 36,
          },
        },
      ],
    }

    resolveTimeout(
      resolve({data}),
      0
    );
  });
};

export const fetchCommunity = (): Promise<APIResponseData<FetchCommunity>> => {
  return new Promise<APIResponseData<FetchCommunity>>((resolve) => {
    logCall('get', 'analytics/audience', {});
    const data = {
      community_count: 1572091,
      genders_per_age: [
        {slice: '13-17', M: 0.05651523, F: 0.13061325},
        {slice: '18-24', M: 0.13976774, F: 0.38364376},
        {slice: '25-34', M: 0.0794589, F: 0.1271451},
        {slice: '35-44', M: 0.02101063, F: 0.03273382},
        {slice: '45-64', M: 0.01366676, F: 0.01544482},
        {slice: '65+', M: 0, F: 0},
      ],
      top_countries: [
        {
          code: 'FR',
          value: 0.62251309,
          cities: [
            {name: 'Paris', value: 0.08376963},
            {name: 'Lyon', value: 0.01727749},
            {name: 'Toulouse', value: 0.01465969},
          ],
        },
        {
          code: 'US',
          value: 0.06544503,
          cities: [
            {name: 'New York City', value: 0.02094241},
            {name: 'Los Angeles', value: 0.0104712},
          ],
        },
        {
          code: 'MA',
          value: 0.06335079,
          cities: [
            {name: 'Casablanca', value: 0.01099476},
            {name: 'Marrakesh', value: 0.00942408},
          ],
        },
        {
          code: 'BE',
          value: 0.03403141,
          cities: [{name: 'Brussels', value: 0.0078534}],
        },
        {
          code: 'CA',
          value: 0.01989529,
          cities: [{name: 'Montreal', value: 0.01099476}],
        },
      ],
      countries: [
        {code: 'FR', value: 0.62251309},
        {code: 'US', value: 0.06544503},
        {code: 'MA', value: 0.06335079},
        {code: 'BE', value: 0.03403141},
        {code: 'CA', value: 0.01989529},
        {code: 'ES', value: 0.01675393},
        {code: 'IT', value: 0.01623037},
        {code: 'DZ', value: 0.01465969},
        {code: 'GB', value: 0.01413613},
        {code: 'CH', value: 0.01413613},
      ],
    };
    resolveTimeout(
      resolve({data}),
      0
    );
  });
};

export const login = ({email, password}): APIResponseDataPromise<FetchLogin> => {
  logCall('post', '/v1/users/login', {user: {email, password}});
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({
        status: 200,
        data: null,
      }),
      500
    );
  });
}

export const register = (params): Promise<APIResponseData<void>> => {
  logCall('post', '/v1/users/register/influencer', params);
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({
        status: 200,
        data: null,
      }),
      500
    );
  });
}

export const registerByInstaCo = (params): Promise<APIResponseData<{ user_was_existing: boolean }>> => {
  logCall('post', '/v2/users', params);
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({
        status: 200,
        data: null,
      }),
      500
    );
  });
}

export const verifyToken = (params): APIResponseDataPromise<FetchLogin> => {
  logCall('get', '/v1/tokens/verify', params);
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({
        status: 200,
        data: null,
      }),
      500
    );
  });
}

export const sendConfirmationRegister = params => {
  logCall('get', '/v1/users/confirmation', params);
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({
        status: 200,
        data: null,
      }),
      500
    );
  })
}

export const sendNewPassword = (params) => {
  logCall('put', '/v1/users/password', params);
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({
        status: 200,
        data: null,
      }),
      500
    );
  })
}

export const resetForgottenPassword = (email): Promise<APIResponseData<void>> => {
  logCall('post', '/v1/users/password', {email});
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({
        status: 200,
        data: null,
      }),
      500
    );
  });
}

export const deleteUserLogout = () => {
  return new Promise<APIResponseData<FetchOk>>((resolve) => {
    logCall(
      'delete',
      '/v1/users/logout',
      {
        headers: {
          Accept: 'application/json',
        },
        withCredentials: true,
      },
    );
    resolveTimeout(
      resolve({
        data: {
          ok: true,
        },
      }),
      500
    );
  });
};

export const generateStatsValues = (dates: string[]) => {
  let tmp;
  const community = dates.map((v, i) => {
    const currentValue = i > 0 && tmp ? tmp + randomRange(-30, 150) : randomRange(1, 1000);
    const pct = randomRange(1, 100) / 100;
    tmp = currentValue;
    return {
      community: currentValue,
      engagement: currentValue * pct,
      content: randomRange(0, 2),
    };
  });
  return community;
};

export const fetchSnaDataChart = (uid, filter: TimeRange = TimeRange.oneYear) => {
  const {start_at, end_at} = getTimeRangeParameters(filter, true);
  logCall(
    'get',
    `snas/${uid}/stats`,
    {
      start_at,
      end_at,
    },
  );

  const dates = generateDates(start_at, end_at);
  const statsValues = generateStatsValues(dates);
  return new Promise<APIResponseData<SnaDataChartFetch>>((resolve) => {
    resolveTimeout(
      resolve({
        data: {
          label: randomFromArray(['facebook', 'instagram', 'youtube', 'twitter', 'tiktok']),
          username: 'Casey Neistat',
          uid,
          data: {
            dates,
            community: statsValues.map((s) => s.community),
            engagement: statsValues.map((s) => s.engagement),
            content: statsValues.map((s) => s.content),
          },
        },
      }),
      500
    )
  });
};

export const postUnsupportedSnaRequest = (data) => {
  logCall(
    'post',
    '/profiles/self',
    {
      [data.type]: data.url,
    },
  );
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({
        id: randomRange(10000, 30000),
      }),
      500
    );
  });
};

export const postSnaRequest = (data, onboardingStep = null) => {
  logCall(
    'post',
    'profiles/self',
    {
      snas: [
        {
          label: data.label,
          uid: data.uid,
          username: data.username,
          picture_url: data.picture_url,
        },
      ],
      onboardingStep,
    },
  );
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({
        id: randomRange(10000, 30000),
      }),
      500
    );
  });
};

export const fetchInfoSna = (type: string, url: string, fast: boolean) =>
  new Promise<APIResponseData<FetchInfoSna>>((resolve) => {
    logCall(
      'get',
      'snas/validate',
      {
        type,
        url,
        fast,
      },
    );
    const token = 'jhsjdf';
    const splittedUrl = url.split('/');
    const username = splittedUrl[splittedUrl.length - 1];
    resolveTimeout(
      resolve({
        status: 200,
        data: {
          uid: '208695472667825',
          token,
          label: type,
          picture_url:
            'https://scontent-arn2-2.xx.fbcdn.net/v/t1.0-1/p200x200/25396214_732178463652854_278218222177645579_n.png?_nc_cat=105&_nc_oc=AQm8UQ-fDqsz_PwCw7-LCgVl7d1Yl-WHuImpd4qJDtOzXxyS2XBLTj-ZUzDg8Zk6Td0&_nc_ht=scontent-arn2-2.xx&oh=e7bc8f97ffacfdf44f4c21c208e10d3f&oe=5E4F4811',
          is_private: false,
          username,
        },
      }),
      500
    );
  });

export const postProfileRequest = (data) =>
  new Promise((resolve) => {
    logCall('put', 'profiles/self', data);
    resolveTimeout(
      resolve({}),
      0
    );
});

export const toggleUserMobilisationEnlisting = (
  enlisted: boolean, // check
) =>
  new Promise((resolve) => {
    logCall(
      'put',
      'profiles/self',
      {
        enlisted,
      },
    );
    resolveTimeout(
      resolve({}),
      0
    );
  });

export const putUserUpdateLocale = (token, locale) =>
  axios.put(
    `${conf.apiRoot}/v1/users/update_locale`,
    {
      locale,
    },
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${token}`,
      },
    },
  );

export const fetchAppInit = (): APIResponseDataPromise<FetchInitApp> =>
  new Promise<APIResponseData<FetchInitApp>>((resolve) => {
    logCall('get', 'app_init', {});
    const data = {
      user_id: 74,
      locale: 'fr-FR',
      is_masquerade: process.env.REACT_APP_MASQUERADE === 'true' || false,
      is_email_confirmed: true,
      is_trial_over: false,
      is_instaconnect_ignored: false,
      is_legal_terms_signed: false,
      onboarding_current_step: process.env.REACT_APP_ONBOARDING_STEP
        ? parseInt(process.env.REACT_APP_ONBOARDING_STEP, 10)
        : null,
      email: `staging+dev@brandandcelebrities.com`,
      first_name: 'Nikola',
      last_name: 'KARABATIC',
      pseudo: null,
      picture_url:
        'https://scontent.cdninstagram.com/a4eddcef535f1c653411f76771bc71dc/5B2F14B4/t51.2885-19/s640x640/26154576_343314312811815_3464028447213355008_n.jpg',
      activity_labels: ['handball'],
      influence_themes: ['sport_fitness'],
      gender: 'male',
      profile_id: 73,
      snapchat: 'zozo',
      twitch: 'https://twitch.tv/zozo',
      enlisted: randomBool(),
      snas: isPlaceholder
        ? []
        : [
          {
            id: 1111,
            uid: '18487188',
            label: 'instagram',
            username: 'Casey Neistat',
            valid_token: true,
          },
          {
            id: 2222,
            uid: '121735755',
            label: 'twitter',
            username: '@caseyn',
          },
          {
            id: 3333,
            uid: '339952045465',
            label: 'facebook',
          },
          {
            id: 4444,
            uid: '12345679',
            label: 'tiktok',
            username: '@coco',
          },
        ],
      quality_score: isPlaceholder ? 0 : Math.random(),
      all_unread_messages_count: randomRange(0, 10),
    }
    resolveTimeout(
      resolve({data}),
      250
    );
  });

export const postAnswer = (token, id, answer_value, answer_text) =>
  axios.request({
    method: 'POST',
    baseURL: `${conf.api}/propositions/${id}/answers`,
    headers: {
      Accept: 'application/vnd.bandc.v1+json',
      Authorization: `Token ${token}`,
      withCredentials: true,
    },
    data: {
      answer_value,
      answer_text,
    },
  });

export const fetchProposition = (token, id) =>
  axios.get(`${conf.api}/propositions/${id}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
      withCredentials: true,
    },
  });

export const fetchSnas = (): APIResponseDataPromise<SNAAPIData[]> => {
  return new Promise<APIResponseData<SNAAPIData[]>>((resolve) => {
    const response: APIResponseData<SNAAPIData[]> = {
      data: isPlaceholder
        ? []
        : Array(3)
          .fill(null)
          .map(() => getRandomSna()),
    };
    resolveTimeout(
      resolve(response),
      500
    );
  });
};

export const fetchSna = (uid): Promise<APIResponseData<SNAAPIData>> => {
  logCall('get', `snas/${uid}`, {});
  return new Promise<APIResponseData<SNAAPIData>>((resolve) => {
    const data = {
      ...getRandomSna(),
      uid,
    };
    resolveTimeout(
      resolve({data}),
      500
    );
  });
};

export const removeSna = (uid: string): ApiPromise<{}> => {
  logCall('delete', `snas/${uid}`, {});
  return new Promise((resolve) => {
    resolveTimeout(resolve({}), 350);
  });
};

export const removeUnsupportedSna = (network: Networks): APIIDResponsePromise<number> => {
  logCall(
    'put',
    'profiles/self',
    {
      [network]: '',
    },
  );
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({
        id: randomRange(10000, 30000),
      }),
      500
    );
  });
};

export const fetchProfileSelf = (): Promise<APIResponseData<FetchProfile>> =>
  new Promise<APIResponseData<FetchProfile>>((resolve) =>
    resolve({
      data: getExampleProfile(),
    }),
  );

export const putProfileSelf = () =>
  new Promise((resolve) => {
    resolveTimeout(
      resolve({}),
      2000
    );
  });

export const putEmail = (state, query) =>
  axios.put(`${conf.apiRoot}/v1/users/update_email`, mapper.updateEmail.toApi(query), getHeaders());

export const resendConfirmationEmail = (email: string): Promise<APIResponseData<void>> =>
  new Promise((resolve) => {
    logCall('post', 'users/resend_confirmation', {email});
    resolveTimeout(
      resolve({status: 204, data: null}),
      500
    );
  });

export const putSettingsChangePassword = (query) =>
  axios.put(`${conf.apiRoot}/v1/users/update_password`, query, {
    headers: getHeaders(),
    withCredentials: true,
  });

export const saveUserToken = (tokenData) => {
  const { uid, ...data } = tokenData;
  logCall('post',`snas/${data.uid}/token`, data);
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({}),
      2000
    );
  });
};

export const saveInstagramPages = (pages: FacebookPagesParams) => {
  logCall('put', '/connect/accounts', pages);
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({}),
      2000
    );
  });
};

export const saveFacebookPages = (pages: FacebookPagesParams) => {
  logCall('put', '/connect/accounts', pages);
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({}),
      2000
    );
  });
};

export const generateRandomTopHashtags = (n: number, filter: TimeRange): FetchTopHashtag[] => {
  const hashtags = [
    ['pomme', 'poire', 'banane', 'chocolat', 'dj'],
    ['music', 'football', 'blackmirror', 'social'],
    ['meuf', 'mec', 'hashtag', 'vacances', 'holidays'],
    ['soleil', 'plage', 'cocotier', 'ballon', 'sport'],
    ['summerbody', 'yolo', 'glaces', 'manger', 'boire'],
    ['vie', 'life', 'vibe', 'music', 'prod', 'bitwig'],
    ['ableton', 'buzz', 'eventide', 'synths', 'studio'],
    ['drums', 'percs', 'top', 'flop', 'spec', 'alcool'],
    ['rosé', 'vin', 'vodka', 'champagne', 'champomy'],
    ['whisky', 'selfie', 'smile', 'leader', 'domination'],
    ['marketing', 'market', 'rencontre', 'meetup', 'yes'],
    ['challenge', 'weekend', 'voiture', 'porsche', 'BMW'],
    ['vinyl', 'netflix', 'movie', 'cinéma', 'campagne'],
    ['green', 'écologie', 'geek', 'computer', 'perfect'],
    ['seychelles', 'hawaii', 'bali', 'paris', 'lyon', 'france'],
    ['newyork', 'berlin', 'amsterdam', 'london', 'travel'],
    ['nature', 'landscape', 'vue', 'montagne', 'ski', 'mer'],
    ['océan', 'waves', 'jetski', 'surf', 'peche', 'chasse'],
    ['chien', 'chat', 'animaux', 'culture', 'peinture', 'expo'],
    ['famille', 'enfants', 'jeux', 'chili', 'brésil', 'amérique'],
  ];

  const filtredHashtags = [
    'soldes',
    'sac',
    'pull',
    'player',
    'cheese',
    'fromage',
    'raclette',
    'reblochon',
    'fondu',
    'savoie',
    'italie',
    'espagne',
    'groland',
    'rave',
    'peach',
    'moi',
    'myself',
    'community',
    'influence',
    'admin',
    'app',
    'iphone',
    'android',
    'robot',
    'future',
    'scifi',
    'horror',
    'futur',
    'business',
    'work',
    'daddy',
    'mum',
    'frère',
    'soeur',
    'chat',
    'chien',
    'summerbody',
    'yolo',
    'glaces',
    'manger',
    'boire',
  ];

  const res = [];
  for (let i = 0; i < n; i++) {
    res.push({
      key: randomFromArray(filter !== TimeRange.all ? filtredHashtags : hashtags[i]),
      engagement_rate: randomRange(20, 100) / 100,
      likes: randomRange(100, 2000),
      comments: randomRange(50, 3000),
    });
  }
  return res;
};

const generateRandomTopFlopPosts = (): FetchTopFlopPosts => {
  return {
    top_posts: Array(3)
      .fill(null)
      .map(() => randomizePost()),
    flop_posts: Array(3)
      .fill(null)
      .map(() => randomizePost()),
  };
};

export const fetchPostsTopHashtags = async (globalFilters: GlobalFilters) =>
  new Promise<APIResponseData<FetchTopHashtag[]>>((resolve) => {
    // TEST FILTERS
    const {start_at, end_at} = getTimeRangeParameters(globalFilters.timeRangeFilter);

    const socialNetworks = Object.keys(globalFilters.socialNetworksFilter).map(
      (i) => globalFilters.socialNetworksFilter[i],
    );
    let networks = socialNetworks.filter(({selected}) => selected === true).map((item) => item.label);
    if (networks.length === 0) {
      networks = socialNetworks.map((item) => item.label);
    }
    const params = {
      social_networks: networks,
      start_at,
      end_at,
    };

    logCall('get', 'posts/top_hashtags', params);

    // END TEST FILTERS

    const data: FetchTopHashtag[] = generateRandomTopHashtags(20, globalFilters.timeRangeFilter);

    const response = {
      status: 200,
      data,
    };
    resolveTimeout(
      resolve(response),
      500
    );
  });

export const fetchTopFlopPosts = async (globalFilters: GlobalFilters) =>
  new Promise<APIResponseData<FetchTopFlopPosts>>((resolve) => {
    const {start_at, end_at} = getTimeRangeParameters(globalFilters.timeRangeFilter);
    const account_ids = getAccountIdsParameters(globalFilters.socialNetworksFilter);

    const params = {
      start_at,
      end_at,
      account_ids,
    };

    // eslint-disable-next-line
    logCall('get', 'posts/top_flop', params);

    const data = generateRandomTopFlopPosts();

    const response = {
      status: 200,
      data,
    };

    resolveTimeout(
      resolve(response),
      500
    );
  });

export const fetchSnaTopPosts = async (params: SNATopPostsParams) =>
  new Promise<APIResponseData<FetchTopFlopPosts>>((resolve) => {
    const {start_at, end_at} = getTimeRangeParameters(params.timeRange);
    const account_ids = [params.snaId];
    logCall('get', 'posts/top_flop', {start_at, end_at, account_ids});

    const data = generateRandomTopFlopPosts();

    const response = {
      status: 200,
      data,
    };

    resolveTimeout(
      resolve(response),
      500
    );
  });

export const postAnswerBrief = async (conversationId, briefId, answer) =>
  new Promise((resolve) => {
    logCall('POST', `/conversations/${conversationId}/briefs/${briefId}/answer`, {data: {answer}});
    const data = {
      created_at: '2020-10-18T09:35:58.000Z',
      message_type: 'notification',
      subtype: 'answer_brief',
      content: answer,
      me: true,
      profile_id: 2511,
      name: 'Christian MONJOU',
      picture_url: null,
      attachments: [],
    };
    resolveTimeout(
      resolve({data}),
      1000
    );
  });

export const fetchPropositions = () => getRandomPropositions();

// Private methods

const getExampleProfile = () => ({
  id: 58,
  first_name: 'Paul',
  last_name: 'POGBA',
  pseudo: '',
  gender: 'male',
  nationalities: ['FR'],
  influence_themes: ['sport_fitness', 'lifestyle'],
  picture_url: 'https://scontent.cdninstagram.com/t51.2885-19/s640.png',
  phone: null,
  website: 'http://paulpogba.fr',
  email: null,
  birthdate: '1993-03-15',
  activities: ['tennis', 'football'],
  addresses: [
    {
      category: 'living',
      g_formatted_address: 'Meudon, France',
    },
  ],
  descriptions: [
    {
      locale: 'fr',
      small_descr: 'Joueur de football international français',
      descr: 'Paul Labile Pogba est un joueur de football intern...',
      full_descr: 'Paul Labile Pogba est un joueur de football intern...',
      key_facts: [
        "Joueur clé de l'équipe de France. \r",
        'Joueur Français au potentiel marketing le plus élevé\r',
        'Un des meilleurs joueurs de Manchester United.',
      ],
    },
  ],
});

const getChance = (threshold) => Math.round(Math.random() * threshold) > 1;

const getRandomAmount = () => Math.round(Math.random() * 1000000) / 100;

const getNullableRandomAmount = () => (getChance(4) ? getRandomAmount() : null);

const getRandomSna = (): SNAAPIData => {
  const sna = randomFromArray(['instagram', 'youtube', 'facebook', 'tiktok']);
  const username = randomFromArray([
    'mafiastuntingofficiel',
    'mafiastunting75',
    'mafia_stunting_officiel',
    'mafiastuning',
    'mso',
  ]);
  const id = randomRange(0, 10000);
  return {
    id,
    uid: id.toString(),
    username,
    url: `https://${sna}.com/${username}`,
    label: sna,
    avg_engagement_rate: randomRange(0, 5000),
    avg_engagement_rate_per_post: randomRange(0, 600) / 100,
    engagement_rate_progression: randomRange(-300, 600) / 100,
    community_count: randomRange(30000, 3000000),
    // community_count: null,
    community_progression_rate: randomRange(-100, 100),
    engagement_details: {
      avg_likes: randomRange(0, 100) * 100,
      avg_dislikes: randomRange(0, 100) * 100,
      avg_comments: randomRange(0, 100) * 100,
      avg_views: randomRange(0, 100) * 100,
      avg_shares: randomRange(0, 100) * 100,
    },
    emv: getNullableRandomAmount(),
    min_emv_per_post: randomRange(0, 1000),
    max_emv_per_post: randomRange(0, 1000),
    emv_per_story: randomRange(0, 1000),
    min_emv_per_story: randomRange(0, 1000),
    max_emv_per_story: randomRange(0, 1000),
  };
};

const getRandomPropositions = (): Promise<APIResponseData<FetchProposition[]>> => {
  return new Promise((resolve) => {
    const response = {
      data: Array(10)
        .fill(null)
        .map(() => getRandomProposition()),
    };
    resolveTimeout(
      resolve(response),
      500
    );
  });
};

const getRandomProposition = (): FetchProposition => ({
  id: randomRange(0, 10000),
  state: randomFromArray(['validated', 'contacted', 'signed', 'canceled']),
  budget: randomBool() ? randomRange(0, 500000) : null,
  project_type: '',
  client_name: 'Lacoste',
  project_name: 'Lorem ipsum et dolor sit amet',
  ends_at: `2019-${randomRange(10, 12)}-${randomRange(10, 30)}T12:00:00+01:00`,
  starts_at: `2019-0${randomRange(1, 9)}-${randomRange(10, 28)}T12:00:00+01:00`,
  social_networks: [
    randomBool() ? 'facebook' : null,
    randomBool() ? 'youtube' : null,
    randomBool() ? 'instagram' : null,
    randomBool() ? 'twitter' : null,
    randomBool() ? 'tiktok' : null,
  ].filter((v) => v !== null),
});

export const deleteUser = (): Promise<any> => {
  logCall('delete', 'user', null);
  return new Promise<any>((resolve) => {
    resolveTimeout(
      resolve({}),
      500
    );
  });
};

export const fetchStatsSharing = (): APIResponseDataPromise<FetchStatsSharing> => {
  logCall('get', 'stats_sharing', {});
  return new Promise<APIResponseData<FetchStatsSharing>>((resolve) => {
    const data = {
      is_working: randomBool(),
      token_expires_at: moment().add(1, 'M').format(),
      is_needed_by_customer: false
    }
    resolveTimeout(
      resolve({data}),
      500
    );
  });
};

const getRequestStatsSharingInfos = (requestId): APIResponseDataPromise<FetchRequestStatsSharingInfos> => {
  logCall('get', `stats_sharing?stats_sharing_request_id=${requestId}`, {});
  return new Promise<APIResponseData<FetchRequestStatsSharingInfos>>((resolve) => {
    resolveTimeout(
      resolve({
        data: {
          requested_by: 'Coca-Cola',
          requests_count: randomRange(1, 10),
        },
      }),
      500
    );
  });
};

const putIgnoredInstaconnect = (isIgnored: boolean) => {
  logCall('put', `${conf.api}/profiles/self`, {is_instaconnect_ignored: isIgnored});
  return new Promise((resolve) => {
    resolveTimeout(
      resolve({}),
      500
    );
  });
};

const postLegalTermSignature = (): APIResponseDataPromise<{ is_legal_terms_signed: boolean }> => {
  logCall('post', `${conf.apiRoot}/v1/self/legal_term_signatures`, {});
  return new Promise((resolve) => {
    resolveTimeout(resolve({ data: { is_legal_terms_signed: true }}), 500);
  });
};

export const api: Api = {
  fetchSnaDataChart,
  fetchPosts,
  fetchPostsTopHashtags,
  fetchCommunity,
  fetchHeader,
  fetchTopFlopPosts,
  login,
  register,
  registerByInstaCo,
  verifyToken,
  sendConfirmationRegister,
  sendNewPassword,
  resetForgottenPassword,
  deleteUserLogout,
  postSnaRequest,
  postUnsupportedSnaRequest,
  removeUnsupportedSna,
  fetchInfoSna,
  fetchSnas,
  postProfileRequest,
  putUserUpdateLocale,
  fetchAppInit,
  postAnswer,
  fetchProposition,
  fetchSna,
  fetchProfileSelf,
  putProfileSelf,
  putEmail,
  resendConfirmationEmail,
  saveFacebookPages,
  putSettingsChangePassword,
  saveUserToken,
  saveInstagramPages,
  removeSna,
  postAnswerBrief,
  fetchPropositions,
  deleteUser,
  toggleUserMobilisationEnlisting,
  fetchSnaTopPosts,
  fetchStatsSharing,
  getRequestStatsSharingInfos,
  fetchConversations,
  getConversationInfos,
  getAllMessages,
  postMessage,
  getDraftMessages,
  postDraftMessage,
  putDraftMessage,
  deleteDraftMessage,
  putIgnoredInstaconnect,
  postLegalTermSignature,
};
