import React, { FC, memo, useRef, useCallback, useMemo, useState, useEffect } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { Editor, EditorState as EditorStateType } from 'react-draft-wysiwyg';
import { Picker } from 'emoji-mart';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { Icon } from '@brandandcelebrities/kolkit';

import { ChatLexique } from 'locales/types/containers/messagingPage';
import { Bold, Italic, Underline, ListOl, ListUl, Indent, Outdent, Link, Unlink } from './Icons';
import LinkDropdown from './LinkDropdown';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './hack-text-editor.scss';
import styles from './TextEditor.module.scss';

const toolbar = {
  options: ['inline','list', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    dropdownClassName: styles.active,
    bold: { icon: Bold, className: styles.button },
    italic: { icon: Italic, className: styles.button },
    underline: { icon: Underline, className: styles.button },
  },
  list: {
    options: ['unordered', 'ordered', 'indent', 'outdent'],
    ordered: { icon: ListOl, className: styles.button },
    unordered: { icon: ListUl, className: styles.button },
    indent: { icon: Indent, className: styles.button },
    outdent: { icon: Outdent, className: styles.button },
  },
  link: {
    link: { icon: Link, className: styles.button },
    unlink: { icon: Unlink, className: styles.button },
    showOpenOptionOnHover: false,
    component: LinkDropdown,
  }
}

interface Props {
  withoutToolbar?: boolean;
  focused?: boolean;
  readOnly?: boolean;
  locale: string;
  lexique: ChatLexique;
  editorState: EditorStateType;
  onOpenDropzone: (droppedFiles: []) => void;
  updateEditorState: (editorState: EditorStateType) => void;
  onPaste?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const TextEditor: FC<Props> = ({ withoutToolbar, focused, locale, lexique, editorState, readOnly, onOpenDropzone, updateEditorState, onFocus, onBlur, onPaste}) => {

  const editorReference = useRef(null);
  const [emojiOn, setEmojiOn] = useState(false);

  useEffect(
    () => focused && editorReference?.current?.focusEditor(),
    [focused, editorReference]
  );

  const localeEditor = useMemo(
    () => {
      const isMac = typeof window !== 'undefined' ? navigator?.platform?.toUpperCase()?.indexOf("MAC") >= 0 : false;
      return {
        locale,
        ...locale === 'en' && {
          translations: {
            'generic.add': 'Add',
            'generic.cancel': 'Cancel',
            'components.controls.link.linkTitle': 'Link Title',
            'components.controls.link.linkTarget': 'Link Target',
            'components.controls.link.linkTargetOption': 'Open link in new window',
            'components.controls.inline.bold': `Bold (${isMac ? '⌘B' : 'Ctrl+B'})`,
            'components.controls.inline.italic': `Italic (${isMac ? '⌘I' : 'Ctrl+I'})`,
            'components.controls.inline.underline': `Underline (${isMac ? '⌘U' : 'Ctrl+U'})`,
            'components.controls.list.unordered': 'Bulleted list',
            'components.controls.list.ordered': 'Numbered list',
          }
        },
        ...locale === 'fr' && {
          translations: {
            'generic.add': 'Ok',
            'generic.cancel': 'Annuler',
            'components.controls.link.linkTitle': 'Titre du lien',
            'components.controls.link.linkTarget': 'Cible du lien',
            'components.controls.link.linkTargetOption': 'Ouvrir le lien dans une nouvelle fenêtre',
            'components.controls.inline.bold': `Gras (${isMac ? '⌘B' : 'Ctrl+B'})`,
            'components.controls.inline.italic': `Italique (${isMac ? '⌘I' : 'Ctrl+I'})`,
            'components.controls.inline.underline': `Souligner (${isMac ? '⌘U' : 'Ctrl+U'})`,
            'components.controls.list.unordered': 'Liste à puces',
            'components.controls.list.ordered': 'Puce numérotée',
          }
        }
      }
    },
    [locale]
  );

  const toggleEmojiPicker = useCallback(
    () => setEmojiOn(prevState => !prevState),
    []
  );

  const handleChangeEmoji = useCallback(
    emoji => {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        emoji?.native,
        editorState.getCurrentInlineStyle(),
      );
      updateEditorState(EditorState.push(editorState, contentState, 'insert-characters'));
      setEmojiOn(false);
    },
    [updateEditorState, editorState]
  );

  const handleReturnKey = useCallback(() => {
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const textWithEntity = Modifier.splitBlock(currentContent, selection);

    updateEditorState(
      EditorState.push(editorState, textWithEntity, 'split-block'),
    );
    return 'handled'; // to signal we handled it ourselves
  }, [updateEditorState, editorState]);

  const emojiPicker = useMemo(
    () => (
      <>
        <Icon title={lexique.pickEmoji} isButton theme="regular" label="smile" onClick={toggleEmojiPicker} />
        {emojiOn && (
          <ClickAwayListener onClickAway={() => setEmojiOn(false)}>
            <div className={styles.emojiPicker} role="dialog">
              <Picker
                native
                onSelect={handleChangeEmoji}
              />
            </div>
          </ClickAwayListener>
        )}
      </>
    ),
    [emojiOn, lexique, handleChangeEmoji, toggleEmojiPicker]
  );

  const renderPlaceholder = useMemo(
    () => {
      const contentState = editorState?.getCurrentContent();
      const type = contentState?.getBlockMap()?.first()?.getType()
      const shouldHidePlaceholder = (
        contentState.hasText() ||
        type === 'unordered-list-item' ||
        type === 'ordered-list-item'
      );

      return shouldHidePlaceholder ? null : lexique.inputPlaceholder;
    },
    [editorState, lexique.inputPlaceholder]
  );

  const toolbarCustomButtons = useMemo(
    () => [
        emojiPicker,
        <Icon title={lexique.pickFile} isButton theme="regular" label="paperclip" onClick={onOpenDropzone} key={1} />,
      ],
    [onOpenDropzone, emojiPicker, lexique.pickFile]
  );

  return (
    <Editor
      ref={editorReference}
      wrapperClassName={styles.wrapper}
      editorClassName={styles.editor}
      toolbarClassName={styles.toolbar}
      toolbar={withoutToolbar ? {options: []} : toolbar}
      toolbarCustomButtons={withoutToolbar ? [] : toolbarCustomButtons}
      editorState={editorState}
      onEditorStateChange={updateEditorState}
      handlePastedText={onPaste}
      readOnly={readOnly}
      placeholder={renderPlaceholder}
      localization={localeEditor}
      onFocus={onFocus}
      onBlur={onBlur}
      handleReturn={handleReturnKey}
      focused
    />
  );
};

TextEditor.displayName = 'TextEditor';

export default memo(TextEditor);
