import { createReducer } from '@reduxjs/toolkit';

import * as UI from 'constants/ui';

const reducers = {
	[UI.LOADER]: (state, { payload: {id, show} }) => {
		const index = state.findIndex(item => item === id);
		if (show) {
			state.push(id);
		} else {
			state.splice(index, 1)
		}
	},
};

export default createReducer([], reducers);
