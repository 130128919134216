import React, { FC, ReactElement, Children, cloneElement, memo, useCallback, useState, useMemo, useEffect } from 'react';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useMeasure from 'react-use-measure'
import { Button } from '@brandandcelebrities/kolkit';

import styles from './DropdownButton.module.scss';

const optionMaxWidth = 292;

interface Props {
  trigger?: ReactElement;
  label?: string | ReactElement;
  theme?: string;
  children: ReactElement[];
  subHeader?: string;
  subHeaderClassName?: string;
  className?: string;
  width?: string;
  size?: string;
  disabled?: boolean;
  onOpen?: (boo: boolean) => void;
}

const DropdownButton: FC<Props> = ({ trigger, label, theme, children, subHeader, subHeaderClassName, className, width, size, disabled, onOpen }) => {
  const [ref, measure] = useMeasure();
  const [open, setOpen] = useState(false);

  useEffect(
    () => onOpen && onOpen(open),
    [open, onOpen]
  );

  const handleOpen = useCallback(
    () => {
      if (!disabled) {
        setOpen(prevState => !prevState);
      }
    },
    [disabled]
  );

  const handleClose = useCallback(
    () => setOpen(false),
    []
  );

  const handleClickItem = useCallback(
    async action => {
      if (action) await action();
      handleClose();
    },
    [handleClose]
  );

  const collapseStyle = useMemo(
    () => ({
      top: size === 'big' ? '42px': '34px',
      maxWidth: measure?.width > optionMaxWidth ? '100%' : optionMaxWidth,
      width: !width
        ? measure?.width > optionMaxWidth ? '100%' : 'auto'
        : `${width}px`
    }),
    [width, size, measure]
  );

  const renderTrigger = useMemo(
    () => {
      if (trigger) {
        return cloneElement(trigger, {
          onClick: handleOpen,
        });
      }

      const cnButton = cn(styles.button, {
        [styles.open]: open
      })

      return (
        <Button
          className={cnButton}
          reverse
          icon="chevron-down"
          label={label}
          onClick={handleOpen}
          theme={theme}
          size={size}
        />
      );
    },
    [handleOpen, trigger, label, open, theme, size]
  );

  const cnWrapper = cn(styles.wrapper, className);
  const cnSubHeader = cn(styles.subHeader, subHeaderClassName);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div ref={ref} className={cnWrapper}>
        {renderTrigger}
        <div className={styles.collapse} style={collapseStyle}>
          <Collapse
            in={open}
            unmountOnExit
            timeout={200}
          >
            <div className={styles.collapseWrapper}>
              {subHeader && (
                <div className={cnSubHeader}>
                  {subHeader}
                </div>
              )}
              <ul className={styles.items}>
                {Children.map(children, el => {
                  const cnItem = cn(styles.item, el?.props?.className);
                  return cloneElement(el, {
                    className: cnItem,
                    onClick: () => handleClickItem(el?.props?.onClick)
                  })
                })}
              </ul>
            </div>
          </Collapse>
        </div>
      </div>
    </ClickAwayListener>
  );
}

DropdownButton.displayName = 'DropdownButton';

DropdownButton.defaultProps = {
  theme: "navy",
  size: "medium"
}

export default memo(DropdownButton);
