import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware, { END } from 'redux-saga';
import thunk from 'redux-thunk';
import { createBrowserHistory, createHashHistory } from 'history';
import _cloneDeep from 'lodash.clonedeep';

import { appsignal } from 'config/appsignal';
import createRootReducer from '../reducers';
import { ReduxSagaStore } from './configureStore.d';

export const history = window.cordova ? createHashHistory() : createBrowserHistory();

const configureStore = (preloadedState): ReduxSagaStore => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, errorInfo) => {
      appsignal.sendError(error, (span) => {
        span.setTags({
            errorInfo: errorInfo.sagaStack,
            currentUrl: window.location.href,
        });
      });
    },
  });
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        routerMiddleware(history),
        sagaMiddleware, // Saga has to be at the end
      ),
    ),
  );
  return {
    ...store,
    runSaga: sagaMiddleware.run,
    close: () => store.dispatch(END),
  };
};

export default configureStore;

export const getStore = () => _cloneDeep(configureStore({}));
