import React, { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Text, Input, Button, Button2, Loader, Icon } from '@brandandcelebrities/kolkit';

import { useLexique, useDispatch, useSelector } from 'utils/redux';
import routes from 'config/routes';
import { submitLogin, resetForgottenPassword, confirmRegister, verifyToken, submitNewPassword } from 'actions/user';
import { changeLocaleRequest } from 'actions/env';
import { AppState } from 'reducers';
import { Locale } from 'locales';
import { history } from 'store/configureStore';

import LoginLexique from 'locales/types/containers/loginPage';

import styles from './Login.module.scss';

const FORMS = {
  login: 'LOGIN',
  resetPassword: 'RESET_PASSWORD',
  newPassword: 'NEW_PASSWORD',
  successRegister: 'SUCCESS_REGISTER',
  tokenError: 'INVALID_TOKEN',
};

const MIN_PASSWORD_SIZE = 6;
interface Selector {
  query?: {
    lang?: string;
    locale?: string;
    token?: string;
    type?: string;
  };
}

const Login = () => {

  const lexique = useLexique<LoginLexique>('containers.loginPage');
  const dispatch = useDispatch();
  const [form, setForm] = useState(FORMS.login);
  const [error, setError] = useState('');
  const [emailSubmited, setEmailSubmited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [tokenStatus, setTokenStatus] = useState({
    tested: false,
    valid: false,
  });

  const { query } = useSelector(
    ({ router }: AppState): Selector => ({
      query: router?.location?.query,
    }),
  );

  useEffect(
    () => {
      if (query.lang || query.locale) {
        const lang = (query.lang || query.locale)?.indexOf('fr') > -1 ? Locale.fr_FR : Locale.en_GB;
        dispatch(changeLocaleRequest(lang));
      }
    },
    [query.lang, query.locale, dispatch]
  );

  useEffect(
    () => {
      (async () => {
        if (query.token && query.type && !tokenStatus.tested) {
          setLoading(true);
          const messageError = await dispatch(verifyToken({
            token: query.token,
            type: query.type
          }));
          if (messageError) {
            setForm(FORMS.tokenError);
            setError(messageError);
          }
          setTokenStatus({
            tested: true,
            valid: !messageError,
          });
          setLoading(false);
        }
      })();
    },
    [query, tokenStatus, dispatch]
  );

  useEffect(
    () => {
      (async () => {
        if (tokenStatus.valid && query.type === 'confirmation') {
          setLoading(true);
          const messageError = await dispatch(confirmRegister(query.token));
          if (messageError) {
            setError(messageError);
          }
          setForm(FORMS.successRegister);
          setLoading(false);
        }

      })();
    },
    [tokenStatus.valid, query, dispatch]
  );

  useEffect(
    () => {
      if (tokenStatus.valid && query.type === 'reset_password') {
        setError('');
        setForm(FORMS.newPassword);
      }
    },
    [tokenStatus.valid, query.type]
  );

  const handleChange = useCallback(
    ({ name, value }) => {
      if (error?.length > 0) setError('');
      setState(s => ({
        ...s,
        [name]: value
      }));
    },
    [error]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      const messageError = await dispatch(submitLogin(state));
      if (messageError) {
        setError(messageError)
      }
      setLoading(false);
    },
    [dispatch, state]
  );

  const handleSubmitEmail = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      setEmailSubmited(false);
      const messageError = await dispatch(resetForgottenPassword(state.email));
      if (messageError) {
        setError('invalidEmail');
        setEmailSubmited(false);
      } else {
        setEmailSubmited(true);
      }
      setLoading(false);
    },
    [dispatch, state.email]
  );

  const handleSubmitNewPassword = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      const errorSize = state.password.trim().length < MIN_PASSWORD_SIZE;
      const mismatchedPassword = state.password.trim() !== '' && state.confirmPassword.trim() !== '' &&  state.password !== state.confirmPassword;
      if (!errorSize && !mismatchedPassword) {
        const messageError = await dispatch(submitNewPassword({
          password: state.password,
          confirmPassword: state.confirmPassword,
          token: query.token
        }));

        if (messageError) {
          setError(messageError);
        } else {
          history.push('/');
        }
      } else {
        setError(errorSize? 'passwordLength' : mismatchedPassword ? 'mismatches' : '')
      }
      setLoading(false);
    },
    [query.token, state.password, state.confirmPassword, dispatch]
  );

  const isDisabledSubmitLogin = useMemo(
    () => state.password.trim() === '' || state.email.trim() === '',
    [state.password, state.email]
  );

  const isDisabledSubmitEmail = useMemo(
    () => state.email.trim() === '',
    [state.email]
  );

  const isDisabledSubmitPassword = useMemo(
    () => state.password.trim() === '' || state.confirmPassword.trim() === '',
    [state.password, state.confirmPassword]
  );

  const loginForm = useMemo(
    () => (
      <form onSubmit={handleSubmit} className={styles.formWrapper}>
        <Text fontTitle tag="h1" className={styles.title}>
          {lexique.logIn}
        </Text>
        <Text className={styles.subtitle}>
          {lexique.register} <Link to={routes.register} className={styles.link}>{lexique.registerLink}</Link>
        </Text>
        <Input
          name="email"
          size="big"
          fullWidth
          placeholder={lexique.email.placeholder}
          onChange={handleChange}
          onEnterPress={handleSubmit}
          value={state.email}
          className={styles.input}
        />
        <Input
          name="password"
          size="big"
          fullWidth
          type="password"
          placeholder={lexique.password.placeholder}
          onChange={handleChange}
          onEnterPress={handleSubmit}
          value={state.password}
          className={styles.input}
        />
        {error && (
          <span className={styles.error}>{lexique.error[error]}</span>
        )}
        <Button
          label={lexique.logIn}
          type="submit"
          size="fat"
          fullWidth
          disabled={isDisabledSubmitLogin}
        />
        <Button
          theme="reset"
          onClick={() => {
            setError('');
            setForm(FORMS.resetPassword);
          }}
          label={lexique.passwordForgotten.title}
          className={styles.linkButton}
          type="button"
        />
      </form>
    ),
    [lexique, state, handleChange, handleSubmit, isDisabledSubmitLogin, error]
  );

  const resetForm = useMemo(
    () => (
      <form onSubmit={handleSubmitEmail} className={styles.formWrapper}>
        <Text fontTitle tag="h1" className={styles.title}>
          {lexique.passwordForgotten.title}
        </Text>
        <Text className={styles.subtitle}>
          {lexique.passwordForgotten.subTitle}
        </Text>
        <Input
          name="email"
          size="big"
          fullWidth
          placeholder={lexique.email.placeholder}
          onChange={handleChange}
          onEnterPress={handleSubmitEmail}
          value={state.email}
          className={styles.input}
          disabled={emailSubmited && !error}
        />
        {error && !emailSubmited && (
          <span className={styles.error}>{lexique.error[error]}</span>
        )}
        {emailSubmited && (
          <h3 className={styles.success}>{lexique.passwordForgotten.submitedEmail}</h3>
        )}
        <Button
          label={emailSubmited ? lexique.passwordForgotten.resendEmail : lexique.passwordForgotten.submit}
          type="submit"
          size="fat"
          fullWidth
          disabled={isDisabledSubmitEmail}
        />
        {emailSubmited && (
          <Button2
            theme="tertiary"
            size="fat"
            className={styles.linkButton}
            label={lexique.logIn}
            onClick={() => {
              setError('');
              setForm(FORMS.login);
            }}
          />
        )}
      </form>
    ),
    [handleSubmitEmail, handleChange, isDisabledSubmitEmail, lexique, state.email, error, emailSubmited]
  );

  const newPasswordForm = useMemo(
    () => (
      <form className={styles.formWrapper} onSubmit={handleSubmitNewPassword}>
        <Text fontTitle tag="h1" className={styles.title}>
          {lexique.newPassword.title}
        </Text>
        <Input
          name="password"
          size="big"
          fullWidth
          type="password"
          placeholder={lexique.password.placeholder}
          onChange={handleChange}
          onEnterPress={handleSubmitNewPassword}
          value={state.password}
          className={styles.input}
        />
        <Input
          name="confirmPassword"
          size="big"
          fullWidth
          type="password"
          placeholder={lexique.newPassword.confirm}
          onChange={handleChange}
          onEnterPress={handleSubmitNewPassword}
          value={state.confirmPassword}
          className={styles.input}
        />
        {error && (
          <span className={styles.error}>{lexique.error[error]}</span>
        )}
        <Button
          onClick={handleSubmitNewPassword}
          label={lexique.newPassword.submit}
          size="fat"
          fullWidth
          disabled={isDisabledSubmitPassword}
        />
      </form>
    ),
    [state.password, state.confirmPassword, isDisabledSubmitPassword, handleSubmitNewPassword, handleChange, error, lexique]
  );

  const tokenErrorForm = useMemo(
    () => (
      <div className={styles.formWrapper}>
        <Text className={styles.message}>
          <Icon
            label="exclamation-triangle"
            width="50"
          />
          {lexique.error[error]}
        </Text>
        <div className={styles.actions}>
          <Button
            onClick={() => history.push('/')}
            label={lexique.logIn}
            fullWidth
          />
        </div>
      </div>
    ),
    [lexique, error]
  );

  const successRegisterForm = useMemo(
    () => (
      <div className={styles.formWrapper}>
        <Text className={styles.message}>
          <Icon
            label="check"
            width="50"
            fill="#30e2bf"
          />
          {lexique.successRegister}
        </Text>
        <div className={styles.actions}>
          <Button
            onClick={() => history.push('/')}
            label={lexique.continue}
            fullWidth
            type="submit"
          />
        </div>
      </div>
    ),
    [lexique]
  );

  return (
    <>
      <Helmet>
        <title>{lexique.headTitle}</title>
      </Helmet>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          {loading && <Loader full background="rgba(255, 255, 255, .8)" /> }
          {form === FORMS.login && loginForm}
          {form === FORMS.resetPassword && resetForm}
          {form === FORMS.newPassword && newPasswordForm}
          {form === FORMS.tokenError && tokenErrorForm}
          {form === FORMS.successRegister && successRegisterForm}
        </div>
      </div>
    </>
  )
};

export default memo(Login);
