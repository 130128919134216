import _get from 'lodash.get';
import { ENUM_TABS, DEFAULT_PER_PAGE } from '../reducers/messaging';
import { editorToMarkdown } from '../utils/richTextEditor';

const mapProfile = p => ({
  id: _get(p, 'id'),
  isActive: _get(p, 'is_active'),
  name: _get(p, 'name'),
  pseudo: _get(p, 'pseudo', ''),
  fullName: _get(p, 'full_name', ''),
  pictureUrl: _get(p, 'picture_url'),
  type: _get(p, 'type', null),
});

const mapAttachment = {
  fromApi: (a) => ({
    id: _get(a, 'id'),
    name: _get(a, 'filename', ''),
    url: _get(a, 'url', ''),
    size: _get(a, 'size', 0),
    base64: _get(a, 'base64', ''),
  }),
  toApi: (a) => ({
    id: a.id,
    name: a.name,
    size: a.size,
    base64: a.base64,
    // eslint-disable-next-line no-underscore-dangle
    _destroy: a._destroy,
  }),
};

const mapMessage = m => ({
  id: _get(m, 'id'),
  createdAt: _get(m, 'created_at', ''),
  content: _get(m, 'content', ''),
  messageType: _get(m, 'message_type', null),
  subject: _get(m, 'subject', ''),
  isThreadStart: _get(m, 'is_a_thread_start', false),
  subtype: _get(m, 'subtype', null),
  writer: mapProfile(_get(m, 'writer')),
  attachments: _get(m, 'attachments', []).map(a => mapAttachment.fromApi(a)),
})

const mapConversation = (data) => ({
  id: data?.id,
  lastMessageAt: _get(data, 'last_message_at', ''),
  lastMessage: mapMessage(_get(data, 'last_message', {})),
  draft: mapDraftMessage(_get(data, 'draft_message', null)),
  hasBeenRead: _get(data, 'has_been_read', true),
  participants: _get(data, 'participants', []).map(mapProfile),
  subject: _get(data, 'subject', ''),
  unreadMessages: _get(data, 'unread_messages_count', 0)
});

export const mapDraftMessage = (data) => data
  ? ({
      id: _get(data, 'id'),
      conversationId : _get(data, 'conversation_id', null),
      createdAt : _get(data, 'created_at', ''),
      updatedAt : _get(data, 'updated_at', ''),
      participants : _get(data, 'participants', []).map(mapProfile),
      content : _get(data, 'content', ''),
      attachments : _get(data, 'attachments', []).map(a => mapAttachment.fromApi(a)),
    })
  : null;

export const getConversations = {
  fromApi: (data) => ({
    conversations: _get(data, 'rows', []).map(mapConversation),
    total: _get(data, 'total', 0),
  }),
  toApi: (parameters) => ({
    name: parameters?.name?.toLowerCase() || '',
    read_status: parameters?.tab || ENUM_TABS.all,
    page: parameters?.page || 0,
    per_page: parameters?.perPage || 20,
  })
}

const mapSubjectThreads = (thread) => ({
  id: _get(thread, 'id', null),
  createdAt: _get(thread, 'created_at', null),
  subject: _get(thread, 'subject', null),
});

export const getConversationInfos = {
  fromApi: (conversation) => {
    const recipients = _get(conversation, 'participants', []);
    return {
      id: _get(conversation, 'id'),
      to: mapProfile(recipients[0]),
      participants: recipients.map(mapProfile),
      subject: _get(conversation, 'subject', ''),
      subjectThreads: _get(conversation, 'threads', []).map(mapSubjectThreads),
      lastMessageAt: _get(conversation, 'last_message_at', ''),
      unreadMessages: _get(conversation, 'unread_messages_count', 0),
      draft: mapDraftMessage(_get(conversation, 'draft_message', null)),
    }
  }
}

export const getAllMessages = {
  toApi: params => ({
    page: params?.page || 0,
    per_page: params?.perPage || DEFAULT_PER_PAGE,
    id: params?.conversationId,
  }),
  fromApi: (data) => ({
    total: data?.total || 0,
    rows: (data?.rows || []).map(mapMessage).reverse()
  })
}

export const postMessage = {
  toApi: message => ({
    content: message?.content || '',
    attachments: (message?.attachments || []).map(a => mapAttachment.toApi(a)),
  }),
  fromApi: message => mapMessage(message)
}

export const newMessageWS = {
  fromApi: data => {
    return mapMessage(data?.message)
  },
  toApi: conversationId => ({
    conversation_id: parseInt(conversationId, 10),
  })
}

export const getConversationsWS = {
  fromApi: data => _get(data, 'conversations', []).map(mapConversation)
}

export const getUnreadMessageWS= {
  fromApi: data => _get(data, 'unread_messages_count',  0),
}

export const getDrafMessages = {
  fromApi: (data) => ({
    conversations: _get(data, 'rows', []).map(mapDraftMessage),
    total: _get(data, 'total', 0),
  }),
  toApi: (parameters) => ({
    name: parameters?.name?.toLowerCase() || '',
    page: parameters?.page || 0,
    per_page: parameters?.perPage || 20,
  })
}

export const postDraftMessage = {
  toApi: draft => ({
    conversation_id: draft?.conversationId,
    content: editorToMarkdown(draft?.content) || '',
    attachments: (draft?.attachments || []).map(a => (a.id && a.destroy)
      ? ({
          id: a.id,
          _destroy: true
        })
      : mapAttachment.toApi(a)
    )
  }),
  fromApi: draft => mapDraftMessage(draft)
}