import { SnaMapper, SnasMapper, SNAAPIData, SNAMappedData, Networks } from 'types/snas.d';

export const mapSna: SnaMapper = {
    fromApi: ({ id, uid, username, url, label, ...stats }: SNAAPIData): SNAMappedData => ({
        stats,
        id,
        uid,
        username,
        url,
        label: Networks[label],
    }),
};

export const mapSnas: SnasMapper = {
    fromApi: (data: SNAAPIData[]): SNAMappedData[] => data.map((sna) => mapSna.fromApi(sna)),
};
