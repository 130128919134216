import React, { PropsWithChildren, createElement, forwardRef } from 'react';
import cn from 'classnames';

import styles from './Typography.module.scss';

// INFO: Just add a variant here
const VARIANTS = [
  'section/title',
  'section/title small',
  'section/title medium',
  'section/title large',
  'label/default',
  'label/large',
  'title/modal-title',
  'text/body small',
  'text/body small bold',
  'text/body medium',
  'text/body medium bold',
  'text/body large',
  'text/body large bold',
] as const;

const VARIANT_STYLES: Record<(typeof VARIANTS)[number], string> = {
  'section/title': styles.sectionTitleSmall,
  'section/title small': styles.sectionTitleSmall,
  'section/title medium': styles.sectionTitleMedium,
  'section/title large': styles.sectionTitleLarge,
  'label/default': styles.labelDefault,
  'label/large': styles.labelLarge,
  'title/modal-title': styles.titleModalTitle,
  'text/body small': styles.textBodySmall,
  'text/body small bold': styles.textBodySmallBold,
  'text/body medium': styles.textBodyMedium,
  'text/body medium bold': styles.textBodyMediumBold,
  'text/body large': styles.textBodyLarge,
  'text/body large bold': styles.textBodyLargeBold,
};

type Props = PropsWithChildren<{
  tag?: string;
  label?: string;
  variant?: (typeof VARIANTS)[number];
  className?: string;
} & React.HTMLAttributes<HTMLElement>>;

const Typography: React.FC<Props> = forwardRef(
  ({ tag = 'span', label, variant, children, className, ...rest }, ref) => {
    const cnSpan = cn(
      styles.text,
      className,
      variant && VARIANT_STYLES[variant],
    );

    return createElement(
      tag,
      {
        ...rest,
        className: cnSpan,
        ref,
      },
      children || label,
    );
  },
);

Typography.displayName = 'Typography';

export default Typography;
