import React, { FC } from 'react';
import classNames from 'classnames';

import './Pagination.scss';
import Back from 'components/svgs/Back';

const defaultProps = {
    offset: 2,
};

interface Props {
    currentPage: number;
    onChange: (index: number) => void;
    totalItems: number;
    itemsPerPage: number;
    offset?: number;
}

const Pagination: FC<Props> = ({ offset, itemsPerPage = 10, totalItems, currentPage, onChange }) => {
    if (!totalItems) return null;

    const lastPage = Math.ceil(totalItems / itemsPerPage) - 1;

    let startingPage = 0;
    let endingPage = lastPage;
    let freeOffset;

    if (currentPage - offset < 0) {
        startingPage = 0;
        freeOffset = offset - currentPage;
        endingPage = Math.min(lastPage, currentPage + offset + freeOffset);
    } else if (currentPage + offset > lastPage) {
        endingPage = lastPage;
        freeOffset = currentPage - lastPage + offset;
        startingPage = Math.max(0, currentPage - offset - freeOffset);
    } else {
        startingPage = currentPage - offset;
        endingPage = currentPage + offset;
    }

    const listPages = Array(endingPage - startingPage + 1)
        .fill('')
        .map((v, i) => {
            const index = startingPage + i;
            return (
                <button
                    type="button"
                    key={index}
                    className={classNames({
                        'bc-analytics-pagination__page': true,
                        'bc-analytics-pagination__page--selected': index === currentPage,
                    })}
                    onClick={() => onChange(index)}
                >
                    {index + 1}
                </button>
            );
        });

    return (
        <div className="bc-analytics-pagination">
            {lastPage >= 1 + 2 * offset && (
                <button type="button" className="bc-analytics-pagination__next" onClick={() => onChange(0)}>
                    <Back color="#9B9B9B" />
                </button>
            )}
            <div className="bc-analytics-pagination__page-group">{listPages}</div>
            {lastPage >= 1 + 2 * offset && (
                <button
                    type="button"
                    className="bc-analytics-pagination__next bc-analytics-pagination__next-forward"
                    onClick={() => onChange(lastPage)}
                >
                    <Back color="#9B9B9B" />
                </button>
            )}
        </div>
    );
};

Pagination.defaultProps = defaultProps;

export default Pagination;
