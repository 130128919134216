import React, { FC, memo } from 'react';
import cn from 'classnames';

import styles from './Avatar.module.scss';

interface Props {
  avatar?: string;
  name: string;
  hide?: boolean;
}

const Avatar: FC<Props> = ({ avatar, name, hide }) => {

  const cnWrapper = cn(styles.wrapper, {[styles.hide]: hide});

  return (
    <div className={cnWrapper}>
        <div className={styles.picture}>
          {avatar && <img src={avatar} alt={name} />}
        </div>
        <span className={styles.name}>{name}</span>
    </div>
  )
};

Avatar.displayName = 'Avatar';

Avatar.defaultProps = {
  hide: false,
}

export default memo(Avatar);
